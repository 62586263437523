function usrAPI(){
	const gFn = this;
	
	this.api = {list:new listAPI()};
	
	this.IS = function(type){
		let usr = fwAPP.api.get('usr');
		let app = (!isEmpty(usr.app) && isValid(usr.app, 'gigzantic')) ?  usr.app.gigzantic : {};
		return {setup:(!isEmpty(app.data)), admin:(!isEmpty(app.access) && String(app.access).indexOf('S') >= 0)};
	};
	
	this.init = function(){
		fwLogger.warn('usrAPI:init');
		
		// implement our overloaded functionality here
		fwAPP.api.menuUPDATEsignedin = function(){
			let actions = [];
			if (fwAPP.usr.IS('usr').admin) actions.push({name:'Admin Dash',icon:'toolbox',text:'dark',onclick:"fwAPP.api.loadACTION('sadash')"});
			actions.push({id:'m-myevents',name:'Events',icon:'calendar-check',text:'dark-gz',onclick:"fwAPP.api.loadACTION('myevents')"});
			actions.push({id:'m-mypasses',name:'Passes/Tickets',icon:'ticket',text:'dark-gz',onclick:"fwAPP.api.loadACTION('mypasses')"});
			actions.push({id:'m-mylists',name:'Contacts',icon:'address-book',text:'primary',onclick:"fwAPP.api.loadACTION('mylists')"});
			actions.push({type:'dropdown-divider'});
			actions.push({id:'m-myvenues',name:'Venues',icon:'shop',text:'primary',onclick:"fwAPP.api.loadACTION('myvenues')"});
			actions.push({id:'m-myacts',name:'Acts',icon:'masks-theater',text:'primary',onclick:"fwAPP.api.loadACTION('myacts')"});
			actions.push({type:'dropdown-divider'});
			actions.push({id:'m-gigpassads',name:'Advertisement',icon:'rectangle-ad',text:'dark',onclick:"fwAPP.api.loadACTION('gigpassads')"});
			actions.push({id:'m-mysettings',name:'Account Settings',icon:'users',text:'secondary',onclick:"fwAPP.api.loadACTION('mysettings')"});
			actions.push({id:'m-support',name:'Support',icon:'circle-info',text:'primary',onclick:"fwAPP.api.support()"});
			actions.push({type:'dropdown-divider'});
			actions.push({name:'Sign-out',icon:'user-minus',text:'dark-gz',onclick:"fwAPP.auth.SIGNOUT()"});
			$('#usr_menu').find('ul').attr('class', 'dropdown-menu dropdown-menu-end rounded-1 small text-start').html(fwHTML.dropdown_menu_li(actions));
			
			$('#cart_action').show();
			
			let usr = fwAPP.api.get('usr');
			let fname = imgMGRapi.bldpath('/cmn/img/', 'usr', usr.id);
			let result = (usr.img) ? imgMGRapi.loadimg('#profile', fname) : $('#profile').addClass('bg-success');
			
			$('#hdr_menu_home').attr('fwapi_param', 'usrdash'); // set based on user settings
		};
		
		fwAPP.api.processACTIONusr = function(event, appdir, frame, page){
			fwLogger.warn('processACTIONusr', appdir, frame, page);
			let pagenav = true;
				
			fwAPP.html.hdrMENU(page);
			switch(page){
				case 'event':
				case 'followup':
				case 'gigpassads':
				case 'gigpassad':
				case 'myacts':
				case 'mypasses':
				case 'mysettings':
				case 'myvenues':
				case 'sadash':
				case 'sendmail':
					fwAPP.html[page]();
					break;

				case 'eventinfo': fwAPP.api.showEVENTinfo(page); break;
				case 'act':	loadPAGEedit('act', 'acttype'); break;
				case 'venue': loadPAGEedit('plc', 'plctype'); break;
				case 'cart':
				case 'checkout': break; // menuUPDATE:cart.resync
				case 'sale': fwAPP.cart.postsale(); break;
					
				case 'mylists':
					pagenav = false;
					fwAPP.api.cacheREQ('get', 'LST_USR', {}, function(opt){
						fwAPP.html[page](opt);
						$('#page_nav').html(fwAPP.html.pagenav(page));
					});
					break;

				case 'myevents':
				case 'evalidate':
					pagenav = false;
					fwAPP.api.cacheREQ('GIGPASSget', 'EVT_LST', {}, function(evtlst){
						fwAPP.api.cacheREQ('GIGPASSget', 'PAS_LST', {}, function(paslst){
							fwAPP.html[page](evtlst);
							$('#page_nav').html(fwAPP.html.pagenav(page));
						});
					});
					break;
					
				case 'usrdash':
					fwAPP.api.menuUPDATEsignedin();
					$('#usraction_top').html(fwAPP.html.usrdash());
					hintMSGS();
					break;
			}
			if (pagenav) $('#page_nav').html(fwAPP.html.pagenav(page));
			
			$('#start_date,#start_time,#hours,#minutes').off('change').on('change',function(){
				gFn.updateEVENTdisplay();
			});
			
			$('form').submit(function(event){
				fwHTML.validate(event, function(){
					gFn.setVALUE();
				});
			});
		};
	};
	
	this.report = function(evtid, pasid){
		let opt = {e:parseInt(evtid)};
		let frame = fwAPP.api.get('frame');
		if (frame == 'mypasses') opt.u = parseInt(fwAPP.api.get('usr').id);
		if (!isEmpty(pasid)) opt.p = parseInt(pasid);
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:'LNK_PDF', opt:opt, callback:function(data){
			let url = '/api/pdf.php?k='+ data.k +'&v='+ data.v;
//				fwLogger.log(evtid, url);
			fwAPP.api.openURL(url);
		}, err_callback:function(result){
		}});
	};
	
	function hintMSGS(){
		function setUSRDASH(data){
			if ('usrdash' != fwAPP.api.get('frame')) return;
			$.each(data, function(k, v){
				$('#card-'+ k).html(v); 
			});	
		}
		let hints = [];
		if (isEmpty(fwAPP.api.get('usrapp'))) fwAPP.api.loadACTION('mysettings');
//		hints.push({title:'User Settings', text:'Finish updating user settings.', onclick:"fwAPP.api.loadACTION('mysettings')"});
		
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'USR_CNT', opt:{extended:false}, callback:function(data){
			setUSRDASH(data);
			if (data.lstcnt == 0) hints.push({title:'Contact List', text:'Start your contact list.', onclick:"fwAPP.api.loadACTION('mylists')"});
			if (data.evtcnt == 0) hints.push({title:'Event', text:'Create a new event.', onclick:"fwAPP.api.loadACTION('event')"});
			hints.push({title:'Advertisement - 2024 National Launch', text:'Create a new advertising campaign.', onclick:"fwAPP.api.loadACTION('gigpassad')"});
			$('#usraction_bottom').html(fwAPP.html.hintMSGS(hints));
		}, err_callback:function(result){
			fwLogger.error('ERROR', result);
		}});
	}		
	
	this.setVALUE = function(){
		let cmd = '';
		let opt = {};
		
		const action = {
			act:function(){
				fwAPP.api.srvREQ({fn:'set', cmd:'ACT_CREAT', opt:{}, callback:function(data){
					fwAPP.api.loadACTIONclean('myacts');
				}, err_callback:function(result){
				}});
			},
			
			venue:function(){
				// address = zipcod.geocode.address
				opt = getFLDS['id','name','address2','type','record'];
				fwAPP.api.srvREQ({fn:'set', cmd:'PLC_SAV', opt:opt, callback:function(data){
					fwAPP.api.loadACTIONclean('myvenues');
				}, err_callback:function(result){
				}});
			},
			
			event:function(){
				opt = getFLDS(['actid','geoid','hours','minutes','name','event_act','pass_amt','pass_qty','pass_type','record','start_date','start_time','description']);
				opt.start_dt = opt.start_date +' '+ opt.start_time;
				opt.duration = opt.hours +'.'+ opt.minutes;
				
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'EVT_CREAT', opt:opt, callback:function(data){
					fwAPP.api.loadACTIONclean('myevents');
				}, err_callback:function(result){
				}});
			},
			
			gigpassad:function(){
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ADV_CREAT', opt:{}, callback:function(data){
					let flds = fwAPP.api.get('var_page');
					let advid = data;
					if (isValid(flds, 'advfn') && !isEmpty(advid)) return fwAPP.usr.adv[flds.advfn](advid);

					fwAPP.api.loadACTION('gigpassads');
				}, err_callback:function(result){
				}});
			},
			
			mysettings:function(){
				if (!$('#imgMGRsave').hasClass('disabled')) $('#imgMGRsave').click();
				let opt = getFLDS(['email','name','fname','mname','lname','legal']);
				
				fwAPP.api.srvREQ({fn:'AUTHset', cmd:'USR_CFG', opt:opt, callback:function(data){
					fwAPP.auth.REVALIDATE(function(data){
						fwHTML.msg('success', 'mysettings', 'information is updated.', 15);
						fwAPP.api.loadACTION('usrdash');
					});
				}, err_callback:function(result){
				}});
			}
		};
		
		let formaction = $('#fwapi-frame').attr('fwapi');
		switch(formaction){
			case 'venue': case 'act': case 'event': case 'mysettings': case 'gigpassad': return action[formaction]();
		}
		fwLogger.error('setVALUE', formaction);
	};
	
	this.pageCACHE = function(callback){
		let cache = fwAPP.api.get('cache');
		
		if (isValid(cache,'actlst') && isValid(cache, 'plclst')){
			if (typeof callback === 'function') callback(cache);
			return;
		}
		
		fwAPP.api.srvREQ({fn:'get', cmd:'USR_LNK', opt:{}, callback:function(data){
			cache.actlst = data.act;
			cache.plclst = data.place;
			fwAPP.api.set('cache', cache);
			if (typeof callback === 'function') callback(cache);
		}, err_callback:function(result){
		}});
	};

	this.venue = {
		plclst:function(){
			let cache = fwAPP.api.get('cache');
//			return isEmpty(cache.actlst) ? {} : cache.actlst;
			return isEmpty(cache.fwsearch) ? {} : cache.fwsearch;
		},
		
		find:function(plcid, callback){
			$.each(gFn.venue.plclst(), function(k, v){
				if (v.id == plcid){
					if (typeof callback === 'function') callback(k, v);
					return false;
				}
			});
		},
		
		usr2plclnk:function(plcid){
			gFn.venue.find(plcid, function(k, v){
				let opt = {plcid:plcid, usr2plclnk:!v.usr2plclnk};
				let html = (v.usr2plclnk ? 'Unlink ?' : 'Link ?');
				html = fwHTML.elem({tag:'div', attr:{'class':'fw-bold text-capitalize text-truncate'}}, html);
				html += fwHTML.elem({tag:'div', attr:{'class':'fst-italic text-bg-dark-gz text-capitalize text-truncate'}}, v.name);
				
				fwAPP.api.myDLG({title:'Venue', html:html, callback:function(){
					fwAPP.api.srvREQ({fn:'set', cmd:'PLC_LNK', opt:opt, callback:function(data){
						$('#srch_srch').click();
					}, err_callback:function(result){
						fwHTML.msg('danger', 'Venue', result.text, 15);
					}});
				}});
			});
		}
	};
	
	this.act = {
		actlst:function(){
			let frame = (fwAPP.api.get('frame') == 'event') ? 'actlst' : 'fwsearch';
			let cache = fwAPP.api.get('cache');
			return isEmpty(cache[frame]) ? {} : cache[frame];
		},
		
		find:function(actid, callback){
			$.each(gFn.act.actlst(), function(k, v){
				if (v.id == actid){
					if (typeof callback === 'function') callback(k, v);
					return false;
				}
			});
		},
		
		act:function(actid){
			let result = {};
			gFn.act.find(actid, function(k, v){
				result = v;
			});
			return result;
		},
		
		usr2actlnk:function(actid){
			gFn.act.find(actid, function(k, v){
				let opt = {actid:actid, usr2actlnk:!v.usr2actlnk};
				let html = (v.usr2actlnk ? 'Unlink ?' : 'Link ?');
				html = fwHTML.elem({tag:'div', attr:{'class':'fw-bold text-capitalize text-truncate'}}, html);
				html += fwHTML.elem({tag:'div', attr:{'class':'fst-italic text-bg-dark-gz text-capitalize text-truncate'}}, v.name);
				
				fwAPP.api.myDLG({title:'Act', html:html, callback:function(){
					fwAPP.api.srvREQ({fn:'set', cmd:'ACT_LNK', opt:opt, callback:function(data){
						$('#srch_srch').click();
					}, err_callback:function(result){
						fwHTML.msg('danger', 'Act', result.text, 15);
					}});
				}});
			});
		},
		
		pageACTION:function(page){
			let html = page;
			switch(page){
				case 'venue': html = 'Confirm to Create a New Venue/Place or Link to existing.'; break;
				case 'act': html = 'Confirm to Create a New Act or Link to existing.'; break;
			}
			fwAPP.api.myDLG({title:'New', html:html, callback:function(){
				fwAPP.api.loadACTION(page);
			}});
		},
		
		pageADD:function(actid){
			let new_actid = isEmpty(actid);
			if (isEmpty(actid)) actid = getFLDS().actid;
			
			if (isEmpty(actid)) return fwHTML.msg('danger','Event Act','invalid act data.',15);
			if (gFn.act.pageFIND(actid)) return fwHTML.msg('warning','Event Act','act is already added to the event.',15);

			let act = gFn.act.act(actid);
			let act_name = isEmpty(act) ? 'unknown' : act.name +' : '+ fmtStr.truncate(act.description);
			let elem = {tag:'input',attr:{type:'text', value:act_name,class:'form-control text-truncate', disabled:'disabled'}};
			let html = fwHTML.elem(elem) + fwHTML.elem({tag:'button',attr:{onclick:'fwAPP.usr.act.pageRMV('+ actid +')',class:'btn btn-outline-danger'}},'<i class="fa-solid fa-minus"></i>');
			let event_act = fwHTML.elem({tag:'input', attr:{id:'event_act[]', value:actid, class:'d-none'}});
			html = fwHTML.elem({tag:'div', attr:{class:'input-group input-group-sm'}}, event_act + html);
			$('#act_container').append(html);
			if (new_actid) gFn.act.pageSEL();
		},
	
		pageSEL:function(){
			if ($('#act_select').is(":visible")){
				$('#act_select').hide();
				return;
			}
			$('#act_select').show();
			$('#actid').html(setSELECT(gFn.act.actlst()));
		},
		
		pageITERATE:function(callback){
			$('[id^=event_act]').each(function(i, v){
		//			if ($(v).prop('value') == id && typeof callback === 'function') callback(i,v);
				if (typeof callback === 'function') return callback(i,v);
			});
		},
		
		pageFIND:function(id){
			let found = false;
			gFn.act.pageITERATE(function(i,v){
				if ($(v).attr('value') == id){
					return !(found = true);
				}
			});
			return found;
		},
		
		pageRMV:function(id){
			gFn.act.pageITERATE(function(i, v){
				if ($(v).attr('value') == id){
					fwLogger.error(id, $(v).attr('value'), i, v);
					$(v).parent().remove();
					return false;
				}
				return true;
			});
		}
	};
	
	this.pageEVENTactivate = function(id){
		fwLogger.log('pageEVENTactivate');
	};

	this.adv = {
		edit:function(advid){
			fwLogger.log('adv.edit', advid);
			let title = 'Ad Edit';
			let html = fwHTML.checkboxSW({id:'cbreview', text:'Submit for Edit'});
			html += 'Editing will start Ad Review Process.'; 
			fwAPP.api.myDLG({title:title, html:html, cbinit:function(){
				let btn = $('.modal-dialog .btn.btn-primary');
				btn.addClass('disabled');
				fwHTML.checkboxSWevent('cbreview', function(opt){
					let result = (opt.action == 'on') ? btn.removeClass('disabled') : btn.addClass('disabled');
				});
			}, callback:function(){
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ADV_EDT', opt:{advid:advid, status:0}, callback:function(data){
					fwAPP.api.loadACTION('gigpassad', advid);
				}, err_callback:function(result){
				}});
			}});
		},
		review:function(advid){
			fwLogger.log('review', advid);
			if (isEmpty(advid)){ // save first
				fwAPP.api.addset('var_page', {advfn:'review'});
				$('[type=submit]').click();
			}
			let title = 'Ad Review';
			let html = fwHTML.checkboxSW({id:'cbreview', text:'Submit for Review'});
			html += 'I certify that to the best of my knowledge; the product or service complies with industry regulations and law; and I have authority to advertise it.'; 
			fwAPP.api.myDLG({title:title, html:html, cbinit:function(){
				let btn = $('.modal-dialog .btn.btn-primary');
				btn.addClass('disabled');
				fwHTML.checkboxSWevent('cbreview', function(opt){
					let result = (opt.action == 'on') ? btn.removeClass('disabled') : btn.addClass('disabled');
				});
			}, callback:function(){
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ADV_EDT', opt:{advid:advid, status:1}, callback:function(data){
					fwHTML.msg('success', title, "Thank you, for trusting us with your ad campaign. Activation can be completed after GIGZANTIC approves your ad.", 30);
					fwAPP.api.loadACTION('gigpassads');
				}, err_callback:function(result){
				}});
			}});
		},
		preview:function(advid, status, callback){
			let sysval = fwAPP.api.get('sysval');
			let viewonly = !isEmpty(callback);
			if (isEmpty(status)) status = 0;
			status = idnameFIND(sysval.optype, status).id;
			
			fwLogger.log('preview', advid, viewonly);

			if (isEmpty(advid)){ // save first
				fwAPP.api.addset('var_page', {advfn:'preview'});
				$('[type=submit]').click();
			}
			
			let id = fwAPP.api.get('param_id');			
			fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'ADV_LST', opt:{id:id, status:status}, callback:function(data){
				let cache = fwAPP.api.get('cache');
				cache.advlst = {data};
				fwAPP.api.set('cache', cache);
				
				let html = fwHTML.icon('backward')+ 'Return to Create Ad';
				html = fwHTML.elem({tag:'button', attr:{class:'btn btn-primary mb-2', onclick:"fwAPP.api.loadACTION('gigpassad','"+ id +"');"}}, html);
				if (viewonly) html = '';
				html += fwAPP.html.preview2adv(data);
				if (!viewonly) html += fwHTML.alert('success', 'Preview sample of how your advertisement will appear on our platform.');
				$("#fwapi-frame").html(html);
				
				if (typeof callback === 'function') callback(data);
			}, err_callback:function(result){
			}});
		}
	};
	
	this.tour = {
		cmd:{}, // empty, name:'new' or name:turid (edit)
		data:[], // currently selected
		sfmt:function(opt){
			let s = '#grp-'+ opt.name + opt.id +' input[type="checkbox"]';
//			'#'+ name + ' input:checkbox[name="evtid"]';
			if (!isEmpty(opt.evtid)) s = '#grp-'+ opt.name + opt.id +' input:checkbox[id="'+ opt.evtid +'"]';
			return s;
		},
		
		actset:function(v, turid){
			if (isEmpty(v.turlst)) return 0;
			if (isEmpty(turid) || turid<1) turid = v.turlst[0].id;
fwLogger.log('actset', v, turid);		
			
			let data = gFn.tour.data;
			if (isEmpty(data)){
				data.push({actid:v.id, turid:turid});
				return turid;
			}
			
			$.each(data, function(i, r){
				if (v.id == r.actid) data[i].turid = turid;
			});
			return turid;
		},
		
		turget:function(v){
			let data = gFn.tour.data;
			
			if (isEmpty(data)) return 0;
			
			let turid = 0;
			$.each(data, function(i, r){
				if (v.id == r.actid) turid = r.turid;
			});
			return turid;
		},
		
		actget:function(v){
			let turid = gFn.tour.turget(v);
			if (turid == 0) return {};
			
			let turlst = {};
			$.each(v.turlst, function(i, r){
				if (turid == r.id) turlst = r;
			});
			return turlst;
		},

		creat:function(actid){
			let name = 'actid' + actid;
			let flds = getFLDS([name]);
			let cnt = countITM(flds[name]);
			
			let sfmt = gFn.tour.sfmt({name:'actid', id:actid});
			let disabled = $(sfmt).prop('disabled');

fwLogger.error('creat', gFn.tour.cmd, disabled, cnt, sfmt);
			if (isEmpty(gFn.tour.cmd[name]) || disabled){
				gFn.tour.cmd[name] = 'new';
				
				gFn.tour.actdo(actid, function(v){
					$('#actid'+ actid).html(fwAPP.html.myact_menu(v, '', 'turid'));
					gFn.tour.disable({name:'actid', id:actid, disabled:false});
				});
				return gFn.tour.notice('new');
			}

			if (cnt<2) return gFn.tour.notice('new');
			return gFn.tour.save(actid);
		},
		
		actdo:function(actid, callback){
fwLogger.log('actdo', actid, gFn.tour.cmd, gFn.act.actlst());			
			$.each(gFn.act.actlst(), function(k, v){
				if (v.id == actid){
					if (typeof callback === 'function') callback(v);
					return false;
				}
			});
		},
		
		save:function(actid){
			let name = 'actid' + actid;
			let flds = getFLDS([name]);
			let cnt = countITM(flds[name]);
			
			let cmd = fwAPP.usr.tour.cmd[name];

			if (cmd == 'new' && cnt<2) return gFn.tour.notice('newmin');
			let title = 'New Tour';
			
			let attr = {id:'name', type:'text', class:'form-control', placeholder:'Enter tour name'};
			
			if (cmd != 'new'){
				title = (cnt==0 ? 'Remove' : 'Update') +' Tour';
				let atur = {};
				gFn.tour.actdo(actid, function(v){
					atur = gFn.tour.actget(v);
					attr.value = atur.name;
					if (cnt==0) attr.disabled = 'disabled';
				});
			}
			
			html = fwHTML.elem({tag:'input', attr:attr});
			
			fwAPP.api.myDLG({title:title, html:html, callback:function(){
				flds = getFLDS([name,'name']);
				let opt = {actid:actid, evtid:flds[name]};
				if (cmd != 'new') opt.turid = cmd.turid;
				let result = (cnt==0) ? opt.remove = true : opt.name = flds.name;
				delete gFn.tour.cmd[name];
				
				fwAPP.api.srvREQ({fn:'set', cmd:'TUR_SAVE', opt:opt, callback:function(data){
					fwAPP.api.loadACTIONclean('myacts');
				}, err_callback:function(result){
					fwHTML.msg('danger', 'Tour', result.text, 15);
				}});
			}});
		},
		
		set:function(actid, turid){
			let name = 'actid' + actid;
			gFn.tour.cmd[name] = {};
			
			gFn.tour.actdo(actid, function(v){
				fwAPP.usr.tour.actset(v, turid);
				$('#grp-actid'+ actid).html(fwAPP.html.myact_tour(v, turid));
				gFn.tour.evtcheck(v);
				gFn.tour.disable({name:'actid', id:v.id, disabled:true});
			});
		},
		
		evtlst:function(opt){
			if (opt.turid<=0 || isEmpty(opt.turlst)) return {};
			let evtid = {};
			$.each(opt.turlst, function(k, v){
				if (opt.turid == v.id){
					evtid = v.evtid;
					return false;
				}
			});
			return evtid;
		},
		
		evtcheck:function(v){
			let atur = fwAPP.usr.tour.actget(v);
			gFn.tour.checkall({name:'actid', id:v.id});
			let evtlst = gFn.tour.evtlst({turid:atur.id, turlst:v.turlst});
			$.each(evtlst, function(k, evtid){
				gFn.tour.checkall({name:'actid', id:v.id, evtid:evtid, check:true});
			});
		},
		
		edit:function(actid, disabled){
			disabled = (!isEmpty(disabled) && disabled);
			
			gFn.tour.actdo(actid, function(v){
				let name = 'actid' + actid;
				let atur = fwAPP.usr.tour.actget(v);
				
				let result = disabled ? delete gFn.tour.cmd[name] : gFn.tour.cmd[name] = {turid:atur.id};
				
				$('#grp-actid'+ actid).html(fwAPP.html.myact_tour(v, atur.id));
				gFn.tour.disable({name:'actid', id:actid, disabled:disabled});
				gFn.tour.evtcheck(v);
				gFn.tour.checkall({name:'actid', id:actid, cmd:'init'});			
				gFn.tour.notice('edit', actid);
			});
		},
		
		disable:function(opt){
			let sfmt = gFn.tour.sfmt(opt);
			let disabled = (!isEmpty(opt.disabled) && opt.disabled);
			
			$(sfmt).each(function(){
				let result = (disabled) ? $(this).prop('disabled', true) : $(sfmt).removeAttr('disabled');
			});
		},
		
		checked:function(opt){
			let sfmt = gFn.tour.sfmt(opt);
			let cnt = 0;
			$(sfmt).each(function(){
				if ($(this).is(':checked')) cnt += 1;
			});
			return cnt;
		},
		
		checkall:function(opt){
			let sfmt = '#grp-'+ opt.name + opt.id +' .dropdown-toggle';
			if (opt.cmd == 'init'){
fwLogger.log('checkall', opt, sfmt);
				$(sfmt).off('show.bs.dropdown').on('show.bs.dropdown', function(event){
					let checked = gFn.tour.checked(opt);
					let onclick = "fwAPP.usr.tour.checkall({name:'actid', id:"+ opt.id +", check:"+ !checked +", notice:true})";
					if (checked){
						$('#checkall'+ opt.id).html( fwHTML.dropdown_li('square-minus', 'Unselect All') ).attr('onclick', onclick);
					} else {
						$('#checkall'+ opt.id).html( fwHTML.dropdown_li('square-check', 'Select All') ).attr('onclick', onclick);
					}
					
					onclick = 'fwAPP.usr.tour.save('+ opt.id +')';
					
					if (checked == 0){
						$('#save'+ opt.id).html( fwHTML.dropdown_li('trash-can', 'Remove Tour') );
					} else {
						$('#save'+ opt.id).html( fwHTML.dropdown_li('circle-check', 'Update / Save') );
					}
					if (checked == 1){
						$('#save'+ opt.id).addClass('disabled');
					} else {
						$('#save'+ opt.id).removeClass('disabled');
					}

					gFn.tour.notice('edit', opt.id);
fwLogger.error('checkall:toggle', checked);
				});
				return;
			}
			let atur = gFn.tour.actget(opt);
			let name = opt.name + opt.id;
			
			sfmt = gFn.tour.sfmt(opt);
			
			let check = (!isEmpty(opt.check) && opt.check);
			let notice = (!isEmpty(opt.notice) && opt.notice);
			if (notice && !check && gFn.tour.cmd[name] != 'new') gFn.tour.notice('uncheck');
			
			$(sfmt).prop('checked', check);
		},
		
		notice:function(mode, actid){
			let msg = 'Feature auto-activates when multiple Events include your Act!';
			let level = 'info';
			let checked = gFn.tour.checked({name:'actid', id:actid});
fwLogger.error('notice', mode, gFn.tour.cmd, checked);
			
			switch(mode){
				case 'newmin':
					level = 'warning';
					/* falls through */
				case 'new':
					if (checked>1) return;
					msg = 'Please select at least two events to create a new tour, then [Tour]> New!';
					break;
				
				case 'uncheck':
					if (checked>1) return;
					msg = 'To remove a tour, unselect all events, then [Tour]> Remove Tour.';
					break;
					
				case 'edit':
					if (checked>1) return;
					msg = 'Select at least 2 events to link a tour, then [Tour]> Update/Save.';
					break;
					
				default:
					let total = {turlst:false, evtlst:false};
					$.each(gFn.act.actlst(), function(k, v){
						let cnt = {turlst:countITM(v.turlst), evtlst:countITM(v.evtlst)};
						if (cnt.evtlst>1) total.evtlst = true;
						if (cnt.turlst>0) total.turlst = true;
						shake('[id^="grp-actid"] .btn-group', true);
					});
					if (total.evtlst){
						let msgplus = total.turlst ? 'and edit ' : '';
						msg = 'Select [Tour] to create '+ msgplus +'tour!';
					}
					break;
			}
			fwHTML.msg(level, 'Tour', fwHTML.icon('link') + msg, 30);
		}
	};
	
	function usrappCFGaccess(){
		let usrapp = fwAPP.api.get('usrapp');
		return (!isEmpty(usrapp) && isValid(usrapp, 'cfg.access')) ? usrapp.cfg.access : [];
	}
	
	this.event = {
		pin:function(id){
			fwAPP.api.pin('set', 'evtid', id);
			fwAPP.api.loadACTION('myevents', {force:true});
		},
		unpin:function(id){
			fwAPP.api.pin('set', 'evtid');
			fwAPP.api.loadACTION('myevents', {force:true});
		},
		
		show:function(id, pasid){ // open tab for adding items
//			let idx = fwAPP.api.get('evtlst', {evtid:id, idx:true});
			if (!isEmpty(pasid)) return $('#collapse-event'+ id +'-'+ pasid).collapse("toggle");

			let evt = fwAPP.api.get('evtlst', {evtid:id});
			$.each(evt.pass, function(i, pas){
				$('#collapse-event'+ id +'-'+ pas.id).collapse("show");
			});
		},
		
		followup:function(evtid, id){
			let evt = fwAPP.api.get('evtlst', {evtid:evtid});
			let fup = fwAPP.api.get('fwsearch', {id:id});
			let status = (fup.status == 'DONE') ? 'OPEN' : 'DONE';
			fwLogger.log(fup, evt);
			fwAPP.api.srvREQ({fn:'set', cmd:'EVT_FUP', opt:{id:id, status:status}, callback:function(data){
				fwAPP.html.followup();
			}, err_callback:function(result){
			}});
		},
		
		access:function(id){
			let gbl = isEmpty(id);
			let evt = gbl ? {name:'New Event'} : fwAPP.api.get('evtlst',{evtid:id});
			fwLogger.log(gbl, evt);
			let title = 'Grant Event Access';
			let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Update');
			footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');

			if (!gbl) fwAPP.api.set('var_page', {evtid:id});
			
			function searchONtype(text, callback){
				if (String(text).length < 3) return;
				
				fwAPP.api.srvREQ({fn:'AUTHget', cmd:'USRLST', opt:{text:text,gbl:gbl}, callback:function(data){
					if (!isEmpty(data)){
						h_access.setdata(data);				
						if (typeof callback === 'function') return callback(h_access.data(true));
					}
				}, err_callback:function(result){
				}});
			}
			
			let html = fwHTML.elem({tag:'select',attr:{id:'access','class':'text-start w-75 m-0',multiple:'multiple',placeholder:'select all existing users requiring access'}});
			html = fwHTML.elem({tag:'span',attr:{class:'input-group-text w-25'}}, 'Access') + html;
			html = fwHTML.elem({tag:'div',attr:{class:'input-group input-group-sm p-0 m-0'}}, html);
			html = fwHTML.elem({tag:'div',attr:{class:'row'}}, html);
			html = fwHTML.subtitle(evt.name) + html;

			let alst = evt.access;
			if (gbl){
				let usrapp = usrappCFGaccess();
				if (!isEmpty(usrapp)) alst = usrapp;
			}  
	
			let h_access = {};
			fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
				let opt = { create: false,
					load: searchONtype,
					plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
					valueField: 'id',
					labelField: 'name',
					searchField: ['name','email']
				};
				
				h_access = myselectize('access', opt);
				h_access.populate(alst, 'id');
			
			},function(event){
				if (fwAPP.api.eventis(event, 'btn-primary')){
					let opt = getFLDS(['evtid','access']);
					
					if (gbl){
						fwAPP.api.set('var_page', {cfg:{access:opt.access}});
						gFn.setVALUE();
					} else {
						fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'EVT_ACCESS', opt:opt, callback:function(data){
							fwHTML.msg('success', 'Event Access', 'User access to event has been updated.', 15);
							fwAPP.api.loadACTIONclean('myevents');
						}, err_callback:function(result){
						}});
					}
				}
				fwAPP.mymodal.hide();
			});
		},
		
		paynote:function(id){
			let evt = fwAPP.api.get('evtlst', {evtid:id});
			let title = 'Update Pay Instructions';
			let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Update');
			footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
			
			fwAPP.api.set('var_page', {evtid:id});
			let html = fwHTML.subtitle(evt.name);
			html += fwHTML.input_textarea({id:'paynote', label:'Pay instructions', name:'Note', value:evt.paynote});

			fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
				autosize($('textarea'));
			},function(event){
				if (fwAPP.api.eventis(event, 'btn-primary')){
					eventTYPEupdate({type:'paynote', evtid:id});
				}
				fwAPP.mymodal.hide();
			});
		},
		
		note:function(id){
			let evt = fwAPP.api.get('evtlst', {evtid:id});
			let title = 'Update Organizer Note';
			let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Update');
			footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
			
			fwAPP.api.set('var_page', {evtid:id});
			let html = fwHTML.subtitle(evt.name);
			html += fwHTML.input_textarea({id:'note', label:'Event organizer note', name:'Note', value:evt.note});
			
			fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
				autosize($('textarea'));
			},function(event){
				if (fwAPP.api.eventis(event, 'btn-primary')){
					eventTYPEupdate({type:'note', evtid:id});
				}
				fwAPP.mymodal.hide();
			});
		},
		
		activate(id){
			let sysval = fwAPP.api.get('sysval');
			let evt = fwAPP.api.get('evtlst',{evtid:id});
			if (isEmpty(evt)) return fwLogger.error('activate', evt);
			
			let title = fwAPP.api.evtOP(evt).init ? 'Activate' : 'Manage Features';
			let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Confirm');
			footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
			
			let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt, evt.duration);
			fwAPP.cart.resync('activate', function(cart){
				if (!isValid(cart,'itm')) return;
				$.each(cart.itm, function(i, o){ // remove from cart if there
					if ((o.accno>410 && o.accno<=415) && id == o.prdid) fwAPP.cart.slermv(o.id);
				});
			});
				
//			let html = fwAPP.html.time(evtdt.s.str,evtdt.e.str);
			let html = fwHTML.subtitle(evtdt.s.str_date +' '+ evt.name);				
			html += fwHTML.elem({tag:'div', attr:{id:'evtstat'}});
			html += fwHTML.elem({tag:'div', attr:{id:'optwrap'}});

			let payinfo = {
				data:{},
				enabled:function(){
					let acct = isValid(this.data, 'acct.amt') ? this.data.acct.amt : {};
					return {sale:(acct['412']>0), advfree:(acct['413']>0)};
				},	
				selstat:function(){
					if (!fwAPP.api.evtOP(evt).init){
						optype = [];
						$.each(sysval.optype, function(k, v){
							let name = String(v.name).toUpperCase();					
							if (evt.status != name){
								if (name == 'ACTIVE' || name == 'PAUSED' || name == 'REMOVED') optype.push({id:v.id, name:name});
							}
						});
						return fwHTML.input('status', 'select', 'Update status', setSELECT(optype));
					}
					return '';
				},
				cmn:function(){
					let data = this.data;
					let html = fwHTML.elem({tag:'div',attr:{'class':'d-flex test-start mb-2'}},'Passes: <b>'+ data.etktcnt +'</b>, Max collection: <b>'+ currency(data.etktmax).format() +'</b>, Promotion: <b>'+ currency(data.evalmax).format() +'</b>');
					$('#evtstat').html(html);
fwLogger.log(evt);					
					if (!payinfo.enabled().sale){
						html = fwHTML.checkboxSW({id:'orgpay', text:'Platform will process all event sale payments', ontext:'I will handle event sale payments myself'});
						html += fwHTML.elem({tag:'div', attr:{id:'hostamt', class:'d-flex text-start fw-bold fst-italic pb-2'}}, 0);
					} else {
						let type = evt.orgpay ? 'org direct sale' : 'platform';
						html = fwHTML.subtitle('Status ['+ type +'] '+ evt.status, fwHTML.icon('toggle-on'));
					}
					
					if (!payinfo.enabled().advfree){
						html += fwHTML.checkboxSW({id:'advfree', text:'Ads. Normal display and search', ontext:'No Ads. Priority display and search!'});
						html += fwHTML.elem({tag:'div', attr:{id:'advfreeamt', class:'d-flex text-start fw-bold fst-italic d-none pb-2'}}, 0);
					} else {
						html += fwHTML.subtitle('Advertisement Free is Enabled.', fwHTML.icon('toggle-on'));
					}
					html += fwHTML.checkboxSW({id:'public', text:'Private Event', ontext:'Public, Shared in Search'});
					html += fwHTML.elem({tag:'div', attr:{id:'payinfo', 'class':'text-start'}});
					html += payinfo.selstat();
					$('#optwrap').html(html);
					
					if (!payinfo.enabled().sale){
						payinfo.off();
						fwHTML.checkboxSWevent('orgpay', function(opt){
							let result = (opt.action == 'on') ? payinfo.on() : payinfo.off();
						});
					} else {
						let result = evt.orgpay ? payinfo.on() : payinfo.off();
					}
					
					if (!payinfo.enabled().advfree){
						fwHTML.checkboxSWevent('advfree', function(opt){
							let result = (opt.action == 'on') ? $('#advfreeamt').removeClass('d-none') : $('#advfreeamt').addClass('d-none');
						});
					}
					
					fwHTML.checkboxSWevent('public').on(evt.public);
					
					html = currency(data.gld).format() +' '+ nameBEAUTIFY(idnameFIND(sysval.accrvn, 413).name);
					$('#advfreeamt').html(html);
				},
				on:function(){
					let amt = this.data;
					let html = fwHTML.elem({tag:'div'},'Event Payment Instructions:' );
					html += fwHTML.elem({tag:'textarea', attr:{id:'paynote', 'class':'form-control'}}, evt.paynote);
					html += fwHTML.elem({tag:'div', attr:{'class':'badge text-bg-light text-start w-100 pb-2'}}, fwHTML.icon('circle-info') + 'Payment instructions are shown online, during event sale.' );
					$('#payinfo').html(html);				
					autosize($('textarea'));
			
					html = currency(amt.slf).format() +' '+ nameBEAUTIFY(idnameFIND(sysval.accrvn, 412).name);
					$('#hostamt').html(html);
				},
				off:function(){
					let amt = this.data;
					$('#payinfo').html('');
					
					let html = currency(amt.hst).format() +' '+ nameBEAUTIFY(idnameFIND(sysval.accrvn, 412).name);
					$('#hostamt').html(html);
				}
			};
			
			fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
				fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'ACC_AMT', opt:{evtid:id}, callback:function(data){
					payinfo.data = data;
					payinfo.cmn();
				}, err_callback:function(result){
				}});
			},function(event){
				let opt = getFLDS(['orgpay','advfree','status','public']);
				opt.evtid = id;
				if (isEmpty(opt.orgpay)) opt.orgpay = fwAPP.api.evtOP(evt).init ? false : evt.orgpay;
				if (isEmpty(opt.advfree)) opt.advfree = false;
				if (isEmpty(opt.public)) opt.public = false;

				let update = [];
				function cleanup(name){
					$.each(update, function(k, v){
						if (v.name == name) v.done = true;
					});
					let done = true;
					$.each(update, function(k, v){
						if (!v.done) done = false;
					});
					fwLogger.log('cleanup', update, done);
					if (!done) return false;
						
					fwAPP.api.cacheCLEAR('evtlst');
					let result = (!payinfo.enabled().sale || (!payinfo.enabled().advfree && opt.advfree)) ? fwAPP.api.loadACTIONclean('cart') : fwAPP.api.loadACTIONclean('myevents');
					return true;
				}
				
				if (fwAPP.api.eventis(event, 'btn-primary')){
					let status = idnameFIND(sysval.optype, evt.status).id;
					if (status != opt.status) fwLogger.warn('STATUS', status, opt.status);
					
					opt.type = 'paynote';
					update.push({name:opt.type, done:false});
					eventTYPEupdate(opt, function(){ // event paynote updated
						if (!payinfo.enabled().sale){
							update.push({name:'412', done:false});
							fwAPP.cart.sleadd(412, id, 0, function(cart){
								eventCFGaccessRESET(id);
								cleanup('412');
							});
						}
						if (!payinfo.enabled().advfree && opt.advfree){
							update.push({name:'413', done:false});
							fwAPP.cart.sleadd(413, id, 0, function(cart){
								cleanup('413');
							});
						}
						cleanup(opt.type);
					});
				}
				fwAPP.mymodal.hide();
			});
		}
	};

	function eventCFGaccessRESET(evtid){
		let access = [];
		$.each(usrappCFGaccess(), function(k, v){
			access.push(v.id);
		});
		if (isEmpty(access)) return;

		opt = {evtid:evtid, access:access};
		fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'EVT_ACCESS', opt:opt, callback:function(data){
			fwHTML.msg('success', 'Event Access', 'User access to event has been updated.', 15);
		}, err_callback:function(result){
		}});
	}
	
	function eventTYPEupdate(opt, callback){
		let type = (opt.type!='note' && opt.type!='paynote' && opt.type!='status');
		if (isEmpty(opt.evtid) || type) return fwLogger.error('eventTYPEupdate', opt);
		
		let fld = getFLDS([opt.type]);
		opt[opt.type] = (isEmpty(fld[opt.type])) ? '' : fld[opt.type];
		fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'EVT_'+ String(opt.type).toUpperCase(), opt:opt, callback:function(data){
			if (typeof callback === 'function') return callback(data);
			fwAPP.api.loadACTIONclean('myevents');
		}, err_callback:function(result){
		}});
	}
	
	this.itemRMV = function(id){
		let sysval = fwAPP.api.get('sysval');
		let pas = fwAPP.api.get('evtlst',{itmid:id,obj:'pas'});
		let itm = fwAPP.api.get('evtlst',{itmid:id,obj:'itm'});
		let title = 'Removing from ['+ idnameFIND(sysval.etktype,pas.type).name +'] Pass?';
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Confirm');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');

		let html = fwHTML.elem({tag:'td'},itm.name);
		html += fwHTML.elem({tag:'td'},'quantity: '+ itm.qty);
		html += fwHTML.elem({tag:'td'},'total value: '+ currency(itm.qty).multiply(itm.amt).format());
		html = fwHTML.elem({tag:'table',attr:{'style':'width:100%'}}, fwHTML.elem({tag:'tr',attr:{'style':'text-transform:capitalize'}},html));
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ITM_RMV', opt:{id:id}, callback:function(data){
					fwAPP.api.loadACTIONclean('myevents');
				}, err_callback:function(result){
				}});
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.itemADD = function(gpsid){
		let sysval = fwAPP.api.get('sysval');
		let btn = 'ADD';
		let title = 'Add Pass Item';
		let footer = '<button type="button" class="btn btn-primary" data-dismiss="modal">'+ btn +'</button>';
		footer += '<button type="button" class="btn btn-secondary" data-dismiss="modal">cancel</button>';

		let	html = fwHTML.input('name','text','Name');
		html += fwHTML.input('type','select','Type',setSELECT(sysval.itmtype,'drink'));
		html += fwHTML.input('qty','number','Quantity',1);
		html += fwHTML.input('amt','number','Estimated Value ($)',15);
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
//			setSELECT(sysval.etktype);
			fwLogger.log('INIT',sysval);
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				let opt = getFLDS(['name','type','qty','amt']);
				opt.pasid = gpsid;
				if (!isValid(opt,'name') || isEmpty(String(opt.name).trim())) opt.name = String(idnameFIND(sysval.itmtype,opt.type).name).replace('_',' ');
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ITM_CREAT', opt:opt, callback:function(data){
					fwAPP.mymodal.hide();
					fwAPP.api.loadACTIONclean('myevents');
				}, err_callback:function(result){
				}});
				return;
			}
			fwAPP.mymodal.hide();
		});
	};

	this.qrscan = function(opt, callback, err_callback){
		let fromScanner = (typeof opt !== 'object');
		if (fromScanner) opt = {id:opt};
		beep();
		let html = fwHTML.elem({tag:'div',attr:{'class':'text-bg-secondary'}}, 'Verifying');
		$('#qr-status').html(html);
		fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'QRSCAN', opt:opt, callback:function(data){
			if (!isValid(data, 'status')) data = {};
			fwLogger.info('QRSCAN:', data);
			let color = (data.status == 'ACCEPTED') ? 'success' : 'primary';
			if (color == 'success') setTimeout(function(){ beep(); }, 250);

			if (fromScanner) fwHTML.msg(color, 'GIGPASS SCAN', opt.id, 15);
			html = fwHTML.elem({tag:'div',attr:{'class':'text-bg-'+ color}}, opt.id);
			$('#qr-status').html(html);
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			html = fwHTML.elem({tag:'div',attr:{'class':'text-bg-danger'}}, opt.id);
			$('#qr-status').html(html);
			if (typeof err_callback === 'function') err_callback(result);
			setTimeout(function(){ beep('error'); }, 250);
		}});
	};
	
	this.eticketACTION = function(action){
		let flds = getFLDS(['etktok']);
fwLogger.error(flds);		
		if (isEmpty(flds)) return fwHTML.msg('warning','GIGPASS','no items are selected to '+ action +' !',15);

		function srvREQ(cmd, opt){
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:cmd, opt:opt, callback:function(data){
				fwHTML.msg('success', 'GIGPASS', 'processing ePasses '+ action +' !', 15);
				fwAPP.api.loadACTIONclean('myevents');
			}, err_callback:function(result){
				fwHTML.msg('danger', 'GIGPASS', result.text, 15);
			}});
		}

		let cache = fwAPP.api.get('cache');
		let alias = '';

		let etktok = [];
		$.each(flds.etktok, function(k, v){
			if ($('#'+ v).attr('name') === 'etktok') etktok.push(v);
			if (!isEmpty(cache.paslst)){ // populate alias
				$.each(cache.paslst,function(i, o){
					if (o.etkid == v && !isEmpty(o.alias)) alias = o.alias;  
				});
			}
		});

		let title = 'GIGPASS ePass';							
		let cmd = 'NOT_ASSIGNED';							
		let html = '<b>'+ etktok.length + fwHTML.plural(' ePass', etktok.length, 'es') +'</b>';
		let opt = {etktok:etktok};
		switch(action){
			case 'release':
				html = 'Returning '+ html +' into the <b>SALE</b> pool.<br>Please Confirm or Cancel below.'; 
				fwAPP.api.myDLG({title:title, html:html, callback:function(){
					srvREQ('ETK_RLS', opt);
				}});
				break;
				
			case 'assign':
				html = fwHTML.checkboxSW({id:'email', text:'Forward to Email'}) + fwHTML.elem({tag:'div', attr:{class:'float-start'}}, 'Assigning '+ html +' to ');
				html += fwHTML.elem({tag:'button',attr:{id:'reset','type':'button','class':'btn btn-small btn-dark rounded-top float-end'}}, fwHTML.icon({name:'circle-xmark',pos:'center'}));
				html += fwHTML.input('name', 'text', 'Name', alias);
				
				fwAPP.api.myDLG({title:title, html:html, cbinit:function(){
					fwHTML.checkboxSWevent('email', function(opt){
						if (opt.action == 'on'){
							$('#name-label').html('Email');
							return;
						}
						$('#name-label').html('Name');
					});
					$('#reset').off('click').on('click', function(event){
						$('#name').val('');
					});
				}, callback:function(){
					let flds = getFLDS();
					let msg = (isValid(flds,'email') && flds.email) ? 'Email' : 'Name';
					if (isEmpty(flds.name)) fwHTML.msg('warning', 'GIGPASS', msg +' '+ action +'ment is removed !', 10);
					if (!isEmpty(flds.email)) opt.email = flds.name;
					else opt.name = flds.name;
					srvREQ('ETK_ALIAS', opt);
				}});
				break;
		}
	};
	
	this.eticketACCEPT = function(etkid){
		let sysval = fwAPP.api.get('sysval');
		let etk = fwAPP.api.get('vpaslst', {etkid:etkid});
		if (isEmpty(etk)) fwLogger.error('eticketACCEPT', etkid);
		fwLogger.error('eticketACCEPT', etk);

		function mylabel(amt){
			return idnameFIND(sysval.etktype, etk.type).name +' '+ fwHTML.elem({tag:'b'}, currency(amt).format());
		}
		let html = fwHTML.checkboxSW({id:'paid', text:'FREE', ontext:'PAID'}) + fwHTML.elem({tag:'div', attr:{id:'mylabel', class:'float-start'}}, mylabel(etk.amt));
		html += fwHTML.elem({tag:'button',attr:{id:'reset','type':'button','class':'btn btn-small btn-dark rounded-top float-end'}}, fwHTML.icon({name:'circle-xmark',pos:'center'}));
		html += fwHTML.input('name', 'text', 'Name', etk.alias);
		html += fwHTML.input('email', 'text', 'Email', '');
		fwAPP.api.myDLG({title:'GIGPASS ePass', html:html, cbinit:function(){
			fwHTML.checkboxSWevent('paid', function(opt){
				if (opt.action == 'on'){
					$("#mylabel").html(mylabel(etk.amt));
					return;
				}
				$("#mylabel").html(mylabel(0));
			});
			$('#paid').click();
			$('#reset').off('click').on('click', function(event){
				$('#email').val('');
			});
		}, callback:function(){
			let flds = getFLDS();
			if (isEmpty(flds.email)) return fwAPP.usr.qrscan(etk.etkid, function(){
				$(window).trigger('srchGETdata', ['']);
			});
			let opt = {email:flds.email,pasid:etk.pasid, etktok:[etk.etkid]};
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ETK_RSV', opt:opt, callback:function(data){
				fwHTML.msg('success', 'GIGPASS', 'processing ePass !', 15);
				$(window).trigger('srchGETdata', ['']);
				fwAPP.api.cacheCLEAR('evtlst');
				fwAPP.api.cacheCLEAR('paslst');
				fwAPP.api.cacheCLEAR('evtsle');
			}, err_callback:function(result){
				fwHTML.msg('danger', 'GIGPASS', result.text, 15);
			}});
		}});
	};
	
	this.eticketRESERVE = function(evtid, pasid, fupid){
		let sysval = fwAPP.api.get('sysval');
		let evt = fwAPP.api.get('evtlst', {evtid:evtid});
		let title = 'Reserve Passes';
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Confirm');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
		
		let fup = fwAPP.api.get('fwsearch', {id:fupid});
		fwLogger.warn(evtid, pasid, fup);
		if (isEmpty(pasid)) pasid = 0;

		function fupval(priority){
			if (isEmpty(fup)) return '';
			if (isEmpty(priority)) priority = 'email'; 
			return isEmpty(fup[priority]) ? fup.email : fup[priority]; 
		}
		
		let pass = [];
		$.each(evt.pass,function(k, v){
			if (v.qty_avail>0) pass.push({id:v.id, name:idnameFIND(sysval.etktype,v.type).name +' ['+ v.qty_avail +']', qty_avail:v.qty_avail});
		});
		fwLogger.warn(pass);
//		let html = fwHTML.input('', 'text', 'Event Name', evt.name );
		let html = fwHTML.subtitle(evt.name);
		html += fwHTML.input('pasid', 'select', 'Pass Type', setSELECT(pass, pasid), (pasid>0));
		html += fwHTML.input('pas_qty', 'select', 'Quantity');
		html += fwHTML.input('pas_val', 'money', 'Total Value', '', true);

		html += fwHTML.elem({tag:'div', attr:{class:'pt-3'}}, fwHTML.checkboxSW({id:'forward', text:'Name', ontext:'Email'}));
		html += fwHTML.input('name', 'text', 'Save for', fupval('note'));
				
		function pass_qty(n,s){
			let qty = [];
			for (let i=1; i<=n; i++) qty.push({id:i,name:i});
			return setSELECT(qty, s);
		}
		
		let opt = {};
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			opt.pasid = $('#pasid').val();
			let pas = fwAPP.api.get('evtlst',{pasid:opt.pasid,obj:'pas'});
			fwLogger.log(pas);
			$('#pas_qty').html(pass_qty(pas.qty_avail,1));
			$('#pas_val').val(currency(pas.amt).format());
			
			$('#pasid').off('change').on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				opt.pasid = $(this).val();
				let pas = fwAPP.api.get('evtlst',{pasid:opt.pasid,obj:'pas'});
				$('#pas_qty').html(pass_qty(pas.qty_avail,1));
				$('#pas_val').val(currency(pas.amt).format());
			});
			
			$('#pas_qty').off('change').on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				let qty = $(this).val();
				pas = fwAPP.api.get('evtlst',{pasid:opt.pasid,obj:'pas'});
				$('#pas_val').val(currency(pas.amt).multiply(qty).format());
			});

			fwHTML.checkboxSWevent('forward', function(opt){
				if (!isEmpty(fup)) fwLogger.log(fup);
				if (opt.action == 'on'){
					$('#name-label').html('Forward to');
					$('#name').prop('id', 'email');
					if (!isEmpty(fup)) $('#email').val(fupval('email'));
					return;
				}
				$('#name-label').html('Save for');
				$('#email').prop('id', 'name');
				if (!isEmpty(fup)) $('#name').val(fupval('note'));
			});
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				let flds = getFLDS();
				opt.qty = flds.pas_qty;
				if (flds.forward) opt.email = flds.email;
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ETK_RSV', opt:opt, callback:function(data){
					fwHTML.msg('success','GIGPASS','processed request for '+ opt.qty +' '+ fwHTML.plural('ePass', opt.qty, 'es')+ '!',15);
					if (!flds.forward && !isEmpty(flds.name) && !isEmpty(data)){
						let etktok = [];
						$.each(data, function(k, v){
							etktok.push(v);
						});
						fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ETK_ALIAS', opt:{name:flds.name, etktok:etktok}, callback:function(data){
							if (!isEmpty(fup)) fwAPP.usr.event.followup(evtid, fupid);
						}, err_callback:function(result){
						}});
					}
					fwAPP.api.loadACTIONclean('myevents');
					fwAPP.mymodal.hide();
				}, err_callback:function(result){
				}});
				return;
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.getImage = function(dest, action){
		let sequence = true;
		
		let target_id = 'target_image';
		let sizer_id = '#imgsize';
		
		if (dest == 'evt'){
			let imgid = fwAPP.api.get('param_id');
			let evt = fwAPP.api.get('evtlst', {evtid:imgid});
			if (!isEmpty(evt) && !fwAPP.api.evtOP(evt).init) return fwLogger.warn(evt);
			target_id += '_'+ imgid;
			sizer_id += '_'+ imgid;
		}
		
		let size = { width: 300, height: 200 };
		switch(dest){
			case 'evt':
			case 'adv':
				sequence = false;
				size = imgMGRapi.sel2size(sizer_id);
				break;
		}
		
		let opt = {id:'dlgimg', remote_id:target_id, root_path:'/cmn/img/', dest:dest};
		opt.boundary = { width: 350, height: 350 };
		opt.viewport = {width: size.width, height: size.height, type: 'square'};
		opt.imgid = fwAPP.api.get('param_id');
		
		if (sequence){
			var cnt = 0;
			$.each($('#mycarousel .carousel-item'), function(k, v){
				if ($(v).hasClass('active')) opt.imgnum = k; // sequence number
				cnt++;
			});
			if (action != 'replace' && action != 'remove'){
				opt.imgnum = cnt; // must be add
				if (cnt > 4) return	fwHTML.msg('warning', 'Upload Image', 'Reached the max number allowed ['+ cnt +'].', 15);
			}
			if (action == 'remove') return $(window).trigger('imgMGRapi', ['remove',opt]);
		}
		imgMGRapi.dlg(opt);
	};
	
	this.myselectizeTYPE = function(id, data){
		if (isEmpty(data)) data = [];
		const type_opt = { create: false, closeAfterSelect: true,
			plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
			valueField: 'id',
			labelField: 'name',
			searchField: ['name'],
			options: nameBEAUTIFY(data, 'name')
		};
		return myselectize(id, type_opt);
	};
	
	this.geoADDRESSedit = function(id){
		let data = fwAPP.api.get('fwsearch', {id:id});
		fwLogger.error('geoADDRESSedit', data);
		
		$('#adredtbtn').remove();
		let addr = $('#address').val();
		$('#address').prop('disabled', false);
		$('.fwapi_form_hide > input, select, textarea').prop('disabled', false);
		$('.fwapi_form_hide').show();
		
		let h_addr = gFn.geoADDRESS();
		$('#address').val(data.street);
		$('#city').val(data.city);
		$('#state').val(data.state);
		$('#zip').val(data.zip);

		h_addr.api().focus();
	};
	
	this.geoADDRESS = function(ignore){
		if (isEmpty(ignore)) ignore = [];
		
		let location = fwAPP.api.myLOCATION();
		let sysval = fwAPP.api.get('sysval');
		
		$('#state').html(setSELECTstate(location.state));
		
		if( $.inArray('type', ignore) < 0 ){
			let h_type = gFn.myselectizeTYPE('type', sysval.plctype);
			// h_type.api().setValue([1]);
		}
		
		function selONchange(text){ // this will be id or text entered
			let data = h_addr.data();
			fwLogger.log('load page based on: '+ text, data);
			if (!isEmpty(data) && Array.isArray(data) && parseInt(text) == (data=data[0]).id){
				$('#address').val(data.street).prop('disabled', true);
				$('#city').val(data.city).prop('disabled', true);
				$('#state').val(data.state).prop('disabled', true);
				$('#zip').val(data.zip).prop('disabled', true);
				$('#address2').focus();
			} else {
				$('#plcid').val('');
				$('#address').prop('disabled', false).val(text);
				$('#city').prop('disabled', false).val('');
				$('#state').prop('disabled', false);
				$('#zip').prop('disabled', false).val('');
				$('#address').focus();
			}

			if (isEmpty(text)) h_addr.api().clearOptions();
		}
		
		function selONtype(text, callback){
			if (String(text).length < 3) return;
			fwLogger.error(text);
			
			fwAPP.api.srvREQ({fn:'get', cmd:'GBL_ADR', opt:{text:text}, callback:function(data){
				h_addr.setdata(data);
				if (typeof callback === 'function') return callback(h_addr.data(true));
			}, err_callback:function(result){
				if (typeof callback === 'function') return callback();
			}});
		}
		
		let opt = { create: false, closeAfterSelect: true,
			onChange: selONchange,
			load: selONtype,
			plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
			valueField: 'id',
			labelField: 'name',
			searchField: ['name']
		};
		
		let h_addr = myselectize('geoid', opt);
		$('#name').focus();
		return h_addr;
	};

	this.setFLDgroups = function(func, data){
		let sysval = fwAPP.api.get('sysval');
		
		if (isEmpty(data)) data = []; // we let it set defaults and inits if required
		
		const action = {
			common:function(){
				if (isValid(data,'name')) $('#name').val(data.name);
				if (isValid(data,'description')) $('#description').val(data.description);
			},
			
			sysval_selectize:function(elem, type){
				if (isValid(data, elem)){
					let h = gFn.myselectizeTYPE(elem, sysval[type]);
					h.api().setValue(JSON.parse('['+ data[elem] +']'));
				}
			},
			
			carousel:function(){
				$('#carousel_replace').addClass('disabled');
				$('#carousel_remove').addClass('disabled');
			},
			
			address:function(){
				if (isValid(data,'geoid') && parseInt(data.geoid)>0){
					fwAPP.api.addset('var_page', {geoid:data.geoid});
					
					$('#address').val(data.address).prop('disabled', true);
					if (isValid(data,'addrplus')) $('#address2').val(data.addrplus);
					$('.fwapi_form_hide').hide(); // hide and disable to skip validation checking of hidden elements
					$('#city').prop('disabled', true);
					$('#state').prop('disabled', true);
					$('#zip').prop('disabled', true);
				} else {
					gFn.geoADDRESS();
				}
			},
			
			gigpassad:function(){
fwLogger.error('gigpassad', data);
				let advid = fwAPP.api.get('param_id');
				if (!isEmpty(advid)) fwAPP.api.addset('var_page', {advid:advid});
				
				if (isValid(data, 'duration')) $('#duration').val(data.duration);
				if (isValid(data, 'target')) $('#target').val(data.target);
				if (isValid(data, 'plan')) $('#plan').val(data.plan);
				if (isValid(data, 'audience')) $('#audience').val(data.audience);
				if (isValid(data, 'url')) $('#url').val(data.url);
				if (isValid(data, 'text')) $('#text').val(data.text);

				$('#duration').val(2).attr('disabled', true); // Quarter - disable for GO2024
				
				action.common();
				action.address();
				action.sysval_selectize('tag', 'plctype');
				$('#name').focus();
				autosize($('textarea'));
			}
		};
		
		switch(func){
			case 'gigpassad': return action[func]();
		}
		fwLogger.error('invalid', func);
	};

	function loadCRSLedit(dest, id, load_callback){
		id = parseInt(id);
		let action = {
			common:function(data){
				let sysval = fwAPP.api.get('sysval');
				let type = dest +'type';
				
				fwAPP.html.checkPUBLIC(data);
				if (isValid(data,'name')) $('#name').val(data.name);
				if (isValid(data,'description')) $('#description').val(data.description);
				if (isValid(data,'type')){
					let h_type = gFn.myselectizeTYPE('type', sysval[type]);
					h_type.api().setValue(JSON.parse('['+ data.type +']'));
				}
				$('#carousel_replace').addClass('disabled');
				$('#carousel_remove').addClass('disabled');
			},
			img:function(images){
				if (isEmpty(images)) return fwLogger.warn('missing images');
				$('#myimages').html(fwAPP.html.carouselIMGS('mycarousel', images));
				$('#carousel_replace').removeClass('disabled');
				$('#carousel_remove').removeClass('disabled');
			},
			act:function(data){
				if (isValid(data,'url')) $('#url').val(data.url);
				autosize($('textarea'));
			},
			plc:function(data){
				if (id > 0){
					let icon = fwHTML.icon({name:'pen-to-square'});

					let btn = fwHTML.elem({tag:'div', attr:{id:'adredtbtn', class:'btn btn-sm text-bg-primary pe-2', onclick:'fwAPP.usr.geoADDRESSedit('+ id +')'}}, icon + 'Edit');
					$('#address').val(data.address).prop('disabled', true).parent().append(btn);
					if (isValid(data,'addrplus')) $('#address2').val(data.addrplus);
					$('.fwapi_form_hide').hide(); // hide and disable to skip validation checking of hidden elements
					$('.fwapi_form_hide > input, select, textarea').prop('disabled', true);
				} else {
					gFn.geoADDRESS();
				}
			}
		};
		
		let nocache = true;
		let cmd = (dest=='act') ? 'ACT_INF' : 'PLC_INF';
		if (id <= 0){ // new record
			let data = {type:''};
			action.common(data);
			action[dest](data);
			if (typeof load_callback === 'function') load_callback(data);
			return;
		}
		
		fwAPP.api.srvREQ({fn:'get', cmd:cmd, opt:{id:id}, callback:function(data){
			if (!data.waccess) return $('#fwapi-frame').html(fwAPP.html.actinfo(0, data));
			fwAPP.api.set('fwsearch', {0:data});
			
			action.common(data);
			action[dest](data);
			
			$('#name').focus();
			$('button[type="submit"]').html(id>0?'Update':'Create');
			
			if (isValid(data,'img')) action.img(data.img);
			if (typeof load_callback === 'function') load_callback(data);
		}, err_callback:function(result){
		}});
	}
	
	function loadPAGEedit(dest, type){
		let imgid = fwAPP.api.get('param_id');
fwLogger.log('loadPAGEedit', dest, type);

		fwAPP.api.set('var_page',{id:imgid});

		loadCRSLedit(dest, imgid, function(data){
			$(window).off('imgMGRapi').on('imgMGRapi', function(event, action, opt){
				fwLogger.log('RELOAD HERE', action, opt);
				
				if (action == 'remove'){
					fwAPP.api.srvREQ({fn:'set', cmd:'IMG_RMV', opt:{dest:opt.dest, imgid:opt.imgid, imgnum:opt.imgnum}, callback:function(data){
						loadCRSLedit(dest, opt.imgid);			
					}, err_callback:function(result){
					}});
					return;
				}
				loadCRSLedit(dest, opt.imgid);			
			});
		});			
	}
	
	this.updateEVENTdisplay = function(dt, duration){
		let opt = {start_dt:dt, hours:duration};
		if (isEmpty(dt) || isEmpty(duration)){
			opt = getFLDS();
			opt.start_dt = opt.start_date +' '+ opt.start_time;
		}
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(opt.start_dt, opt.hours, opt.minutes);
		let display = fwAPP.html.time(evtdt.s.str, evtdt.e.str);
		
		$('#event_display').html(display);
	};
	
	gFn.init();
	return gFn;
}