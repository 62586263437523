function listAPI(){
	const gFn = this;
	
	this.init = function(){
		fwLogger.warn('init');
		return gFn;
	};
	
	function dlg(opt){ 
		if (isEmpty(opt)) opt = {};
		let title = isEmpty(opt.title) ? 'List' : opt.title;
		let cmd = isEmpty(opt.cmd) ? 'LST_ADD' : opt.cmd;
		let name = isEmpty(opt.name) ? '' : opt.name;
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Confirm');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
		
		let html = fwHTML.input('name', 'text', 'Name', name);
		let lstusr = fwAPP.api.get('lstusr');
		if (!isEmpty(lstusr) && cmd == 'LST_ADD') html += fwHTML.input('plstid', 'select', 'Add to list', setSELECT(lstusr, '', {empty:'-'}));
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
		}, function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				let flds = getFLDS(['name', 'plstid']);
				flds.type = 'list';
				fwAPP.api.srvREQ({fn:'set', cmd:cmd, opt:flds, callback:function(data){
					fwAPP.api.loadACTIONclean('mylists');
				}, err_callback:function(result){
					fwHTML.msg('danger', 'List', result.text +', please contact our support if you believe this to be an error.', 15);
				}});
			}
			fwAPP.mymodal.hide();
		});
	}
	
	function find(opt){
		let value = {};
		let lstcur = fwAPP.api.get('lstcur');
		$.each(lstcur, function(k, v){
			if (!isEmpty(opt.idx) && v.idx == opt.idx){
				value = v;
				return false;
			} else if (!isEmpty(opt.id) && v.id == opt.id && v.type == opt.type){
				value = v;
				return false;
			}
			return true;
		});
		return value;
	}
	
	this.copy = function(opt){
		function clipboard(t){
			var $i =  $('<textarea>');
			$('body').append($i);
			$i.val(t).select();
			document.execCommand('copy');
			$i.remove();
		}
		
		let i = find(opt);
		if (!isEmpty(i)){
			let t = (!isEmpty(i.email) && i.name != i.email) ? '"'+ i.name +'" <'+ i.email +'>' : i.email;
			if (isEmpty(i.email)) t = i.name;
			clipboard(t);
			fwHTML.msg('success', 'Clipboard', 'Copied <b>'+ i.name +'</b> information to clipboard.', 2);
			beep('ping');
		}
	};
	
	this.availLIST = function(id, internal){
		if (isEmpty(internal)) internal = true;
		let lstusr = fwAPP.api.clone(fwAPP.api.get('lstusr'));
		$.each(lstusr, function(k, v){
			if (v.id == id){
				delete(lstusr[k]);
			} else if (internal){
				let i = find({id:v.id, type:'list'});
				if (!isEmpty(i)) delete(lstusr[k]);
			}
		});
		return lstusr;
	};
	
	const cb = {
		sfmtspan:function(name, id, end){
			let s = (name == 'lstidx') ? '#lstusr-' : '#evtctnr';
			end = isEmpty(end) ? '' : ' '+ end;
			return s + id +end;
		},
		sfmt:function(name, id){
			let s = (name == 'lstidx') ? '#collapse-lstusr-' : '#evtctnr';
			s += id +' input[name="'+ name +'"]';
			if (isEmpty(id)) s = 'input[name="'+ name +'"]';
			return s;
		},
		
		get:function(s, checked){
			checked = (!isEmpty(checked) && checked);
			let result = [];
			$(s).each(function(){
				let id = $(this).prop('id');
				if (checked){
					if ($(this).is(':checked')) result.push(id);
				} else {
					result.push(id);
				}
			});
			return result;
		},
		
		lst:{
			available:function(evtid){
				return cb.get(cb.sfmt('lstid', evtid));
			},
			
			selected:function(evtid){
				let lst = [];
				let cbsel = cb.get(cb.sfmt('lstid', evtid), true);
				let evt = fwAPP.api.get('evtlst', {evtid:evtid});
				$.each(cbsel, function(k, v){
					$.each(evt.lstcur, function(i, l){
						l.type = 'list';
						if (v == l.id) lst.push(l);
					});
				});
				return lst;
			},
		},
		
		idx:{
			available:function(id){
				return cb.get(cb.sfmt('lstidx', id));
			},
			
			selected:function(id){
				let lst = [];
				fwLogger.error('selected', cb.sfmt('lstidx', id));
				let cbsel = cb.get(cb.sfmt('lstidx', id), true);
				fwLogger.error('selected', cbsel);
				$.each(cbsel, function(k, v){
					let i = find({idx:v});
					if (!isEmpty(i)) lst.push(i);
				});
				return lst;
			},
		},
	};
	
	function showIFinfo(info){
		if (!isEmpty(info) && String(info).indexOf('OPRULE_USRLSTMAX')>=0){
			fwHTML.msg('info', 'List', 'Exceeded a number of contacts allowed in a list', 15);
			return true;
		}
		return false;
	}
	
	function selectITEMSview(opt){
fwLogger.error('selectITEMSview', opt);
		let html = '';
		let id = opt.plstid;
		let lst = isEmpty(opt.evtid) ? cb.idx.selected(opt.id) : cb.lst.selected(opt.evtid);
		if (isEmpty(lst)) return html;
		
		$.each(lst, function(k, v){
			html += fwHTML.elem({tag:'div', attr:{class:'col text-bg-dark-gz rounded-1 m-1'}}, fwAPP.html.fmtLSTname(v));
		});
		html = fwHTML.elem({tag:'div', attr:{class:'col text-bg-light-gz m-1'}}, fwHTML.icon('list')) + html;
		return fwHTML.elem({tag:'div', attr:{class:'row row-cols-auto g-3 fst-italic'}}, html);
	}
	
	this.edit = function(opt){
		let lstusr = fwAPP.api.get('lstusr');
		fwLogger.log('edit', opt, lstusr);
		
		if (isEmpty(opt)) opt = {};
		if (isValid(opt, 'global')){
			switch(opt.global){
				case 'edit':
					let plstid = opt.id;
					if (isEmpty(plstid)) plstid = fwAPP.api.pin('get', 'plstid');
					if (isEmpty(plstid)) return fwLogger.error('missing value', plstid);
					let name = fwAPP.api.get('lstusr',{id:plstid}).name;
					fwAPP.api.set('var_page', {lstid:plstid});
					dlg({title:'List Name', cmd:'LST_EDT', name:name});
					break;
					
				case 'new': dlg({title:'Add List'}); break;
				default: dlg();	break;
			}
		}
		return gFn;
	};
	
	this.rmvitm = function(opt){ // items only live in mylists
		let plstid = fwAPP.api.pin('get', 'plstid');
		let s = cb.sfmt('lstidx');
		fwLogger.log('rmvitm', opt, s);
		$(s).prop('checked', false); // unselect all
		
		s = cb.sfmt('lstidx', plstid);
		fwLogger.log('rmvitm', opt, s);
		$(s).each(function(){
			if ($(this).prop('id') == opt.id) $(this).prop('checked', true);
		});
		fwAPP.usr.api.list.rmv({id:plstid});
	};
	
	this.rmv = function(opt){
		fwLogger.log('rmv', opt);
		if (isEmpty(opt)) opt = {};

		let myaction = opt.remove ? 'Remove' : 'Unlink';
		if (opt.global) myaction = 'Remove';
		let mytype = isEmpty(opt.evtid) ? 'item' : 'invite list';
		let acnt = countITM(isEmpty(opt.evtid) ? cb.idx.available(opt.id) : cb.lst.available(opt.evtid));
		mytype = fwHTML.plural(mytype, acnt);

		let html = opt.global ? 'Removing list and everything in it?' : selectITEMSview(opt);
		if (isEmpty(html)) return fwHTML.msg('info', 'List', 'Please select '+ mytype + ' to '+ myaction, 15);
		
		html = dlgOPTtitle(opt) + html;
		
		fwAPP.api.myDLG({title:myaction, html:html, callback:function(){
			opt.lst = cb.lst.selected(opt.evtid);
			fwAPP.api.srvREQ({fn:'set', cmd:'LST_RMV', opt:opt, callback:function(data){
				fwAPP.api.loadACTIONclean((isEmpty(opt.evtid)?'mylists':'myevents'));
			}, err_callback:function(result){
				fwHTML.msg('danger', 'List '+ myaction, result.text, 15);
			}});
		}});
	};
	
	this.mv = function(opt){
		let html = selectITEMSview(opt);
		if (isEmpty(html)) return fwHTML.msg('info', 'List Move', 'Please select items to Move !', 15);
		
		html = dlgOPTtitle(opt) + html;
		
		let lstusr = gFn.availLIST(opt.id, false);
		html += fwHTML.input('plstid', 'select', 'To List', setSELECT(lstusr, ''));
		
		fwAPP.api.myDLG({title:'Moving', html:html, callback:function(){
			let flds = getFLDS(['plstid']);
			opt.plstid = flds.plstid;
			opt.lst = cb.idx.selected(opt.id);
			fwAPP.api.srvREQ({fn:'set', cmd:'LST_MV', opt:opt, callback:function(data){
				fwAPP.api.loadACTIONclean('mylists');
			}, err_callback:function(result){
				if (!showIFinfo(result.text)) fwHTML.msg('danger', 'List', 'Failed on: '+ result.text, 30);
			}});
		}});
	};
	
	function dlgOPTtitle(opt){
		if (isEmpty(opt.name)){
			let lstid = isEmpty(opt.lstid) ? opt.id : opt.lstid;
			opt.name = isEmpty(opt.evtid) ? fwAPP.api.get('lstusr', {id:lstid}).name
				: fwAPP.api.get('evtlst', {evtid:opt.evtid}).name;
		}
		return fwHTML.subtitle(opt.name);
	}
	
	this.link = function(opt){
fwLogger.error('link', opt);
		let html = dlgOPTtitle(opt);
		if (isEmpty(opt)) opt = {};
		let lstusr = gFn.availLIST(opt.id);
		if (isEmpty(lstusr)) return fwHTML.msg('info', 'Link', 'No lists are available to link', 15);
		
		html += fwHTML.input('lstid', 'select', 'List', setSELECT(lstusr, ''));
		fwAPP.api.myDLG({title:'Link', html:html, callback:function(){
			let flds = getFLDS();
			opt = isEmpty(opt.evtid) ? {plstid:opt.id, type:flds.type} : {evtid:opt.evtid};
			if (!isEmpty(flds.lstid)){
				opt.type = 'list';
				opt.lstid = flds.lstid; 
			}
			
			fwAPP.api.srvREQ({fn:'set', cmd:'LST_ADD', opt:opt, callback:function(data){
				fwAPP.api.loadACTIONclean((isEmpty(opt.evtid)?'mylists':'myevents'));
			}, err_callback:function(result){
				fwHTML.msg('danger', 'LST_ADD', result.text, 15);
			}});
		}});
	};
	
	this.contact = function(opt){
		function optedt(opt){
			return {id:opt.id, name:opt.text, type:'email'};
		}
		function optadd(opt){
			return {lstid:opt.lstid, name:opt.name, text:opt.text, type:'email'};
		}
		
		if (isEmpty(opt)) opt = {};
		if (isEmpty(opt.lstid) && isEmpty(opt.id)) return fwLogger.error('contact:lstid | id');
		let edit = !isEmpty(opt.id);
		let title = (edit ? 'Edit' : 'Add')+ ' contact';
		fwLogger.log( opt );
		
		let html = edit ? '' : dlgOPTtitle(opt);
		html += fwHTML.input('email', 'email', 'Email', edit ? opt.email : '');
		html += fwHTML.input('name', 'text', 'Name', edit ? opt.name : '');
		
		fwAPP.api.myDLG({title:title, html:html, cbinit:function(){
			$('#email').prop('disabled', edit);
		}, callback:function(){
			let flds = getFLDS(['name','email']);
			if (!isEmpty(flds.email)) opt.name = flds.email; 
			if (!isEmpty(flds.name)) opt.text = flds.name;
			let cmd = edit ? 'USR_EDT' : 'LST_ADD';
			
			opt = edit ? optedt(opt) : optadd(opt);
			fwAPP.api.srvREQ({fn:'set', cmd:cmd, opt:opt, callback:function(data){
				fwAPP.api.loadACTIONclean((isEmpty(opt.evtid)?'mylists':'myevents'));
			}, err_callback:function(result){
				fwHTML.msg('danger', cmd, result.text, 15);
			}});
		}});
	};
		
	this.add = function(opt){
		if (isEmpty(opt)) opt = {};
		
		function setOPT(){
			let html = '';
			switch ($('#type').val()){
				case 'email':
					html = fwHTML.input('email', 'email', 'Email', '');
					html += fwHTML.input('name', 'text', 'Name', '');
					break;
					
				case 'list':
					let lstusr = gFn.availLIST(opt.id);
					if (isEmpty(lstusr)) break;
					html = fwHTML.input('lstid', 'select', 'List', setSELECT(lstusr, ''));
					break;
			}
			$('#delta').html(html);
		}
		
		let html = dlgOPTtitle(opt);
			
		if (isEmpty(opt.evtid)){
			let selection = [{id:'email', name:'Email Contact'},{id:'list', name:'List'}];
			html += fwHTML.input('type', 'select', 'Type', setSELECT(selection, 'list'));
			html += fwHTML.elem({tag:'div', attr:{id:'delta'}});
		} else {
			html += fwHTML.spinner();
		}
		
		fwAPP.api.myDLG({title:'Add/Link', html:html, cbinit:function(){
			if (isEmpty(opt.evtid)){
				setOPT();
				$('#type').off('change').on('change', function(event){
					setOPT();
				});
			} else {
				fwAPP.api.cacheREQ('get', 'LST_USR', {}, function(lstusr){
					fwAPP.api.srvREQ({fn:'get', cmd:'LST_USR', opt:opt, callback:function(data){
						let lstcur = fwAPP.api.set('lstcur', data);
						lstusr = fwAPP.api.clone(lstusr);
						$.each(lstusr, function(k, v){
							$.each(lstcur, function(i, j){
								if (v.id == j.id) delete(lstusr[k]);
							});
						});
						
						let html = dlgOPTtitle(opt);
						html += fwHTML.input('lstid', 'select', 'List', setSELECT(lstusr, ''));
						$('.modal-body').html(html);
					}, err_callback:function(result){
					}});
				});
			}
		}, callback:function(){
			let flds = getFLDS();
			opt = isEmpty(opt.evtid) ? {plstid:opt.id, type:flds.type} : {evtid:opt.evtid};
			if (!isEmpty(flds.lstid)) opt.lstid = flds.lstid; 
			if (!isEmpty(flds.email)) opt.email = flds.email; 
			if (!isEmpty(flds.name)) opt.name = flds.name;
			
			fwAPP.api.srvREQ({fn:'set', cmd:'LST_ADD', opt:opt, callback:function(data){
				fwAPP.api.loadACTIONclean((isEmpty(opt.evtid)?'mylists':'myevents'));
			}, err_callback:function(result){
				fwHTML.msg('danger', 'LST_ADD', result.text, 15);
			}});
		}});
	};
	
	function cacheEVTlst(cmd, opt, callback){
		let offset = String(cmd).replace('_','').toLowerCase();
		let cache = fwAPP.api.get('cache');
		if (isValid(cache, offset)){
			let found = false;
			$.each(cache[offset],function(k,v){
				if (opt.evtid == v.evtid && opt.type == v.type){
					if (typeof callback === 'function') callback(v);
					return !(found=true);
				}
			});
			if (found) return;
		}
		
		let fn = (opt.type == 'attending') ? 'GIGPASSget' : 'get';
		
		fwAPP.api.srvREQ({fn:fn, cmd:cmd, opt:opt, callback:function(data){
			opt.lst = data;
			if (typeof callback === 'function') callback(opt);
			
			if (isEmpty(cache[offset])) cache[offset] = [];
			cache[offset].push(opt);
			while (cache[offset].length > 5) cache[offset].shift(); // keep the cache small
		}, err_callback:function(result){
		}});
	}
	
	this.checkall = function(opt){
		let s = cb.sfmt(opt.name, opt.id);
		function ischecked(){
			let checked = false;
			$(s).each(function(){
				if ($(this).is(':checked')) checked = true;
			});
			return checked;
		}
		
		function enable(flag){
			for (const v of ['export','unlink','move','evtupdate']){
				let result = flag ? $('#'+ v + opt.id).removeClass('disabled') : $('#'+ v + opt.id).addClass('disabled');
			}
		}
		
		fwLogger.error('checkall', opt);
		
		if (opt.cmd != 'init') $(s).prop('checked', !ischecked()); // on init we just arm toggle
		
		let ss = cb.sfmtspan(opt.name, opt.id, '.dropdown-toggle');
		$(ss).off('show.bs.dropdown').on('show.bs.dropdown', function(event){
			fwLogger.warn('checkall', opt, ischecked());
			
			if (ischecked()){
				$('#checkall'+ opt.id).html( fwHTML.dropdown_li('square-minus', 'Unselect All') );
				enable(true);
			} else {				
				$('#checkall'+ opt.id).html( fwHTML.dropdown_li('square-check', 'Select All') );
				let result = ($(s).length == 0) ? $('#checkall'+ opt.id).addClass('disabled') : $('#checkall'+ opt.id).removeClass('disabled');
				enable(false);
			}
		});
	};
	
	this.evtUPDATE = function(evtid){
		let title = 'Send Event Update';
		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, fwHTML.icon('envelopes-bulk') +'Send');
		footer += fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-secondary', 'data-dismiss':'modal'}},'X');
		
		let badge = fwHTML.elem({tag:'label', attr:{class:'badge bg-success w-100 text-start m-0 p-0'}}, fwHTML.elem({tag:'input',attr:{id:'forward', type:'checkbox'}}) +' Email');
		let evt = fwAPP.api.get('evtlst', {evtid:evtid});
		let acnt = countITM(cb.lst.available(evtid));
		
		let html = selectITEMSview({evtid:evtid});
		if (isEmpty(html)) return fwHTML.msg('info', 'Event Update', 'Please select invite '+ fwHTML.plural('list', acnt)+ ' to send update!', 15);
		
		html = fwHTML.subtitle(evt.name) + html;
		html += fwHTML.input_textarea({id:'notify', label:'Event update', name:'Note', value:evt.notify});
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			let opt = getFLDS(['lstid']);
			fwAPP.api.srvREQ({fn:'get', cmd:'LST_CNT', opt:opt, callback:function(data){
				if (!isValid(data, 'usrcnt') || !isValid(data, 'lstcnt')) return fwLogger.error('LST_CNT', data);
				
				html = data.usrcnt + fwHTML.plural(' email', data.usrcnt)+', traversed '+ data.lstcnt + fwHTML.plural(' list', data.lstcnt);
				$('.modal-body').append(fwHTML.subtitle(html));
			}, err_callback:function(result){
			}});
		}, function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				
				let opt = getFLDS(['notify']);
				opt.evtid = evtid;
				if (isEmpty(opt.notify)) return fwHTML.msg('warning', 'Event Update', 'Please enter text to send update!', 15);
				
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'EVT_NOTE', opt:opt, callback:function(data){
					opt = getFLDS(['lstid']);
					opt.evtid = evtid;
					
					fwAPP.api.srvREQ({fn:'set', cmd:'EVT_NOTIFY', opt:opt, callback:function(data){
						if (!isValid(data, 'total') || !isValid(data, 'inqueue') || !isValid(data, 'added')){
							return fwLogger.error('EVT_NOTIFY', data);
						}
						fwAPP.mymodal.hide();
						html = data.total + fwHTML.plural(' email', data.total)+' submitted.';
						if (data.added != data.total)	html += ' '+ data.added + ' added now';
						if (data.inqueue>0)	html += ', '+ data.inqueue +' added earlier';
						fwHTML.msg('success', title, html, 15);
					}, err_callback:function(result){
					}});
				}, err_callback:function(result){
				}});
				return;
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.export = function(id){
		function download(name, data) { 
			if (isEmpty(name) || isEmpty(data)) return fwLogger.error('download', name, data);
			
			name = trimWS(name);
			const blob = new Blob([data], {type:'text/csv'}); 
			const url = window.URL.createObjectURL(blob); 
			const a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', name); 
			a.click();
		}
  
		function csvmaker(data) { 
			csvRows = []; 
			$.each(data, function(k, v){
				if (k==0){
					const headers = Object.keys(v); 
					csvRows.push(headers.join(','));
				}
				const values = Object.values(v).join(','); 
				csvRows.push(values);
			});
			return csvRows.join('\n'); 
		}
		
		let name = fwAPP.api.get('lstusr', {id:id}).name;
		let html = fwHTML.subtitle(name);

		let opt = {type:'usr', lstid:[]};
		let explst = [];
		let cbsel = cb.idx.selected(id);

		if (isEmpty(cbsel)) return fwHTML.msg('info', 'Export Contacts', 'Please select items to export.',15);
		$.each(cbsel, function(k, v){
			if (v.type == 'email'){
				explst.push({id:v.id, email:v.email, name:v.name});
			} else if (v.type == 'list'){
				opt.lstid.push(v.id);
			}
		});

		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary disabled', 'data-dismiss':'modal'}}, fwHTML.icon('download') +'Download');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}}, 'X');
		html += fwHTML.spinner();
		
		fwAPP.api.myMODAL(fwHTML.title('Export Contacts'), html, footer, function(){
			fwAPP.api.srvREQ({fn:'get', cmd:'LST_CNT', opt:opt, callback:function(data){
				if (isValid(data, 'usr') && data.usrcnt > 0){
					$.each(data.usr, function(k, v){
						if (!explst.find(o => o.id === v.id)) explst.push(v);  
					});
				}

				$.each(explst, function(k, v){
					if (v.email == v.name) v.name = '';
					delete v.id;
				});

				html = fwHTML.subtitle(name);
				html += fwHTML.icon('download') + fwHTML.elem({tag:'div', attr:{'class':'pre-wrap'}}, 'Selected '+ data.lstcnt +' List(s), '+ explst.length +' Total Contact(s)');
				
				$('.modal-body').html(html);
				if (explst.length > 0) $('.btn-primary').removeClass('disabled');
			}, err_callback:function(result){
			}});
		}, function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				let fname = trimWS(name) +'.csv';
				let csvdata = csvmaker(explst); 
				download(fname, csvdata);
				fwHTML.msg('success', 'Export Contacts', 'Please check download folder for [<b>'+ fname +'</b>] file.',15);
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.import = function(id){
		let title = 'Import contacts into list';
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary disabled','data-dismiss':'modal'}}, fwHTML.icon('upload') +'Upload');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');

		let icon = fwHTML.icon('file-csv') +'Select File';
		let html = fwHTML.elem({tag:'button',attr:{id:'fileGET', 'type':'button', 'class':'btn btn-dark-gz p-0 px-1', 'data-bs-toggle':'tooltip', 'data-bs-title':'Select Your File'}}, icon);
		html += fwHTML.elem({tag:'input',attr:{id:'fileUPLOAD', 'type':'file', 'style':'display:none;'}});

		html = fwHTML.subtitle('csv: Name, Email') + html;
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			$('#fileGET').off('click').on('click', function(event){
				$('input:file').change(function(){
					let data = $('#fileUPLOAD')[0].files[0];
					$('#fileGET').html(fwHTML.icon('file-csv') + data.name);
					$('.btn.btn-primary').removeClass('disabled');
				});
				
				$('#fileUPLOAD').click();
			});
		}, function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				let data = $('#fileUPLOAD')[0].files[0];
				let usr = fwAPP.api.get('usr');
				let name = usr.id +'_'+ id +'.'+ data.name.split('.').pop();
				fwAPP.api.upload('upload', name, data, function(result){
					if (result.status != 'ok') return fwLogger.error(result, data);
					fwAPP.api.srvREQ({fn:'set', cmd:'LST_IMP', opt:{lstid:id, name:name}, callback:function(data){
						fwHTML.msg('success', 'List', 'Accepted: '+ data.accepted +', Items: '+ data.items +', Imported:'+ data.success, 15);
						showIFinfo(data.info);
						fwAPP.api.loadACTIONclean('mylists');
						fwAPP.mymodal.hide();
					}, err_callback:function(result){
						if (!showIFinfo(result.text)) fwHTML.msg('danger', 'List', 'Failed on: '+ result.text, 30);
						fwAPP.api.loadACTIONclean('mylists');
						fwAPP.mymodal.hide();
					}});
				});
				return;
			}
			fwAPP.mymodal.hide();
		});
	};
	
	return gFn.init();
}