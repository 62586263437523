function cartAPI(){
	const gFn = this;
	
	function isCART(cart){
		if (isEmpty(cart)) cart = fwAPP.api.get('cart');
		return !(isEmpty(cart) || isEmpty(cart.itm) || isEmpty(cart.crtid));
	}
	
	function cartID(){
		let id = 0;
		let cart = fwAPP.api.get('cart');
		return isCART(cart) ? parseInt(cart.crtid) : 0;
	}
	
	function cartITMS(){
		let cart = fwAPP.api.get('cart');
		return isCART(cart) ? cart.itm : {};
	}
	
	function cartITERATE(callback){
		$.each(cartITMS(), callback);
	}
	
	function cartSHOW(){
		let usr = crtUSR();
fwLogger.error('cartSHOW', usr);
		
		if (usr.id>0 && fwAPP.api.modeUI()){
			let cart = fwAPP.api.get('cart');
			let qty = (isEmpty(cart.qty) || (priv.ischeckout() && cart.locked)) ? 0 : cart.qty;
			$('#cart_item_cnt').removeClass('bg-secondary').addClass('bg-dark-gz').html(qty);
			return;
		}
		
		$('#cart_item_cnt').removeClass('bg-dark-gz').addClass('bg-secondary').html(0);
	}
	
	function itemIS(o, type){
		return !isEmpty(o[type]);
	}
	
	function checkPROMO(){
		let check = {adv:false, promo:false};
		$.each(cartITMS(), function(i, o){
			if (o.accno == '433') check.adv = true;
			if (o.accno == '613') check.promo = true;
		});
		return check;
	}
	
	function defaultMSG(cart){
		if (!fwAPP.auth.SIGNEDIN()) return;
		
		let check = checkPROMO();
		if (check.adv){
			if (check.promo){
				$(".alert").alert('close');
				return setTimeout(function(){ fwAPP.api.windowRESIZE(true); }, 350);
			}				
			let html = '<b>PROMO CODE</b> / 2024 National Launch activation code: <b>GO2024</b>';
			html = fwHTML.alert('success', html);
			$("#fwapi-sysmsg").prepend(html);
			setTimeout(function(){ fwAPP.api.windowRESIZE(true); }, 350);
			setTimeout(function(){ shake('#promo');	}, 2000);
		}
	}

	let priv = {
		iscart:function(){
			let frame = fwAPP.api.get('frame');
			return (frame=='cart');
		},
		ischeckout:function(){
			let frame = fwAPP.api.get('frame');
			return (frame=='checkout');
		},
		iscart_and_checkout:function(){
			let frame = fwAPP.api.get('frame');
			return (frame=='checkout' || frame=='cart');
		},
		info:function(o){
			let html = priv.img(o) +'['+ o.prdname +']';
			if (o.accno == 412) html += fwHTML.elem({tag:'div', attr:{class:'fw-medium text-small'}}, '[<i>activation</i>]');
			if (!isEmpty(o.description)){
				html += '&nbsp;'+ o.description;
			}
			html = fwHTML.elem({tag:'span', attr:{class:'d-inline-flex'}}, html);
			return fwHTML.elem({tag:'td', attr:{class:'text-start'}}, html);
		},
		amt:function(o){
			let html = fwHTML.elem({tag:'div',attr:{'class':'col text-end'}}, priv.qty(o) + currency(o.amt).format());
			html = fwHTML.elem({tag:'div', attr:{'class':'row'}}, html);
			html += priv.subtotal(o);
			return fwHTML.elem({tag:'td'}, html);
		},
		btn:function(o){
			let attr = {class:'badge text-bg-dark rounded', onclick:'fwAPP.cart.slermv('+ o.id +')'};
			return (priv.iscart() && o.removable) ? fwHTML.elem({tag:'span', attr:attr}, 'remove') : '';
		},
		rmvqty:function(o){
			function fmt(s){
				return fwHTML.elem({tag:'td', attr:{class:'text-end'}}, s);
			}
			let html = '';
			if (o.accno != '431') return fmt(priv.btn(o));
			if (priv.iscart()){
				let aqty = [];
				for (let i=0; i<=o.qty_avail; i++) aqty.push({id:i,name:i?i:'0 (Remove)'});
				html = fwHTML.elem({tag:'select', attr:{'class':'form-select-sm',id:'qty[]',name:o.id}}, setSELECT(aqty, o.qty)); 
			}
			return fmt(html);
		},
		qty:function(o){
			if (priv.iscart() && o.accno == '431') return '';
			return (o.qty > 1) ? o.qty + ' qty x ' : '';
		},
		subtotal:function(o){
			if (o.accno != '431') return '';
			let html = '<b>Subtotal: '+ currency(o.qty).multiply(o.amt).format() +'</b>';
			html = fwHTML.elem({tag:'div',attr:{'class':'col text-end'}}, html);
			return fwHTML.elem({tag:'div',attr:{'class':'row'}}, html);
		},
		img:function(o){
			if (o.accno != '431' && o.accno != '433') return '';
			let defimg = (o.accno == '433') ? '/cmn/img/advertisement433.jpg' : '/cmn/img/band.jpg';		
			let src = imgMGRapi.useimg(o.img, {width:250, height:250}, defimg);
			return fwHTML.elem({tag:'img',attr:{'width':50,'height':50,'src':src,'class':'d-none d-md-block img-fluid justify-content-start p-1'}});
		}
	};
	
	function cartDRAW(cart){
		if ((priv.iscart() && !isCART(cart)) || typeof cart !== 'object'){
			cart = fwAPP.api.set('cart');
			$('#total_amt').html('Total: '+ currency(0).format());
			$('#cart_list').html('');
			return cartSHOW();
		}
		
		fwLogger.error('cartDRAW', 'checkout:'+ priv.ischeckout(), cart);
		cartSHOW();

		let html = '';
		$.each(cart.itm, function(i, o){
			if (!isEmpty(o.itmamt))	o.itmamt = parseFloat(o.itmamt);
			o.accno = String(o.accno);
			o.amt = parseFloat(o.amt);
			o.qty = parseInt(o.qty);
			
			switch(o.accno){
				case '100':
					o.qty = o.qty_avail = 1;
					/* falls through */				
				case '431':
					o.qty_avail = parseInt(o.qty_avail);
					if (o.qty_avail>15) o.qty_avail = 15;				
					break;
					
				case '433':
					defimg = itemIS(o, 'discount') ? '/cmn/img/advertisement432.jpg' : '/cmn/img/advertisement.jpg';
					break;
					
				case '412': case '413': case '414': case '432': case '442': case '444': case '445': break;
				default: fwLogger.error('accno', o); break;
			}
			
			html += fwHTML.elem({tag:'tr',attr:{'class':'border-bottom'}}, priv.info(o) + priv.rmvqty(o) + priv.amt(o));
		});
		
		if (priv.ischeckout()){
			let usr = crtUSR();
			let delivery = usr.name +' &lt;'+ usr.email +'&gt; '+ fwHTML.icon((usr.verified==1 ? 'check':'circle-question'));
			
			$('#delivery').html(fwHTML.elem({tag:'div',attr:{'class':(usr.verified ? 'text-dark':'text-primary')}}, delivery));
			let payment_opt = fwHTML.elem({tag:'div',attr:{'id':'payment_opt','class':'d-inline-flex pe-2 align-middle'}});
			
			$('#payment').html(payment_opt + fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary',onclick:'fwAPP.cart.payment()'}},'Select Payment Option'));
			$('#total_itm').html(cart.qty + fwHTML.plural(' item', cart.qty, 's'));
			$('#total_amt').html(currency(cart.amt).format());
			$('#cart_list').html(html);

			let payment = fwAPP.api.get('cache').payment;
			if (!isEmpty(payment)){
				$('#place_order').prop('disabled',false);
				let icon = fwHTML.icon({name:'credit-card',type:'solid',text:'primary',size:'2xl'});
				if (payment.paypal) icon = fwHTML.icon({name:'cc-paypal',type:'brands',text:'primary',size:'2xl'});
				else if (payment.ach) icon = fwHTML.icon({name:'money-check',type:'solid',text:'dark',size:'2xl'});
				$('#payment_opt').html( icon );
			}
		} else {
			$('#total_amt').html('Total: '+ currency(cart.amt).format());
			
			$('#cart_list').html(html);
			
			$('[id^=qty]').off('change').on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				let itmid = $(this).attr('name');
				let qty = $(this).val();
				item.update(itmid, qty);
			});
		}
		defaultMSG(cart);
	}
	
	function getDLGpage(page){
		let cache = fwAPP.api.get('cache');
		let sysval = fwAPP.api.get('sysval');
		
		if (isEmpty(page)){
			page = $('#paypal').prop('checked') ? 'payment' : 'payment_ach';
			page = $('#ach').prop('checked') ? 'payment_ach' : 'payment';
		}
		cache.dlgpage = page;
		
		fwAPP.api.getPAGE(page, $('#my_dlg_page'), function(o){
			$('[type=button].btn-primary').prop('disabled', false);
			switch(page){
				case 'payment':
					$('#state').html(setSELECTstate('IL'));
					break;
					
				case 'payment_ach':
					$('#ach_acct').html( 'No linked accounts found' );
					$('[type=button].btn-primary').prop('disabled',true);
					fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'ACH_ACC', opt:{}, callback:function(data){
						let acct = data;
						$('#ach_acct').html( fwHTML.elem({tag:'select',attr:{id:'use_ach_acct',class:'form-select text-capitalize'}}, setSELECT(acct)) );
						$('[type=button].btn-primary').prop('disabled',false);
					}, err_callback:function(result){
					}});
					break;
					
				case 'payment_ach_setup':
					let routingno_lookup = '';
					$('#type').html(setSELECT(sysval.achtype,'checking'));
					$('#routingno').off('focusout').on('focusout', function(e){
						let opt = {};
						opt.rn = String($('#routingno').val()).trim();
// https://stackoverflow.com/questions/40134200/bootstrap-validator-to-validate-length-of-field-correct-syntax
						if (opt.rn.length != 9) fwHTML.msg('danger', 'Routing Number', 'Routing numbers are 9 digits', 15);
						else if (routingno != routingno_lookup){
							fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'RNL_GET', opt:opt, callback:function(data){
								if (data.code != 200) return fwHTML.msg('danger', 'Routing Lookup', data.message, 15);
								cache.dlgpage.acc_name = data.customer_name;
								let bankinfo = data.customer_name +' / '+ data.city +' / '+ data.zip;
								$('#bankinfo').val(bankinfo);
								$('#name').val(data.customer_name);
								fwLogger.error(data);
								routingno_lookup = routingno;
							}, err_callback:function(result){
							}});
						}
					});
					break;
			}
			$('[name=payoption]').off('change').on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				$('[type=button].btn-primary').prop('disabled',false);
				if ($('#paypal').prop('checked')){
					$('#my_dlg_page').html( fwHTML.elem({tag:'img', attr:{'style':'height:50vh', 'src':'/cmn/img/paypal_w600_h450.jpg', 'class':'img-fluid img-thumbnail'}}) );
				} else getDLGpage(); // reload myself
			});
		});
	}

	function verifyUSR(email, callback){
		if (isEmpty(email)) return;
		
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:'USRIS', opt:{type:'email',text:email}, callback:function(data){
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
		}});
	}
	
	function crtUSR(){
		let usr = fwAPP.api.get('usr');
		if (isEmpty(usr) || usr.id == 0) fwLogger.error('crtUSR', usr);
		return usr;
	}
	
	this.show = function(){
		let cart = fwAPP.api.get('cart');
		
		if (isCART(cart) || cart.qty > 0){
			if (!priv.iscart()) return fwAPP.api.loadACTION('cart', {force:true});
		}
		shake('#cart_action', 'ping');
	};
	
	this.postsale = function(){
		fwLogger.error('todo:sale:record');
	};
	
	this.slermv = function(itmid){
		let sel = {}; // verify selection
		cartITERATE(function(i, o){
			if (itmid == o.id) sel = o;
		});
		if (isEmpty(sel)) return fwLogger.error('slermv', itmid);
		
		let depsel = [];
		depsel.push(itmid); 
		cartITERATE(function(i, o){ // remove dependencies
			if (sel.prdid==o.prdid && sel.accno==412 && o.accno==413) depsel.push(o.id); 
			if (sel.accno==433 && o.accno==613) depsel.push(o.id); 
		});
		
		item.crtgrpupd('CRT_ITM_RMV', depsel);
	};
	
	this.sleadd = function(accno, prdid, prdtyp, callback){
		if (accno<400 && accno>599) return fwLogger.error('cart', accno, prdid, prdtyp);
		
		if (fwAPP.auth.SIGNEDIN()) return item.add(accno, prdid, prdtyp, callback);
			
		let usr = fwAPP.api.get('usr');
		if (!isEmpty(usr) && isValid(usr, 'email') && parseInt(usr.verified)) return item.add(accno, prdid, prdtyp);
		
		usr = fwAPP.signin.usr();
		let email = isValid(usr, 'email') ? usr.email : '';
		
		let btn = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-text-bg-dark-gz', 'data-dismiss':'modal'}}, fwHTML.icon('user-plus') +'Sign-in');
		let footer = fwHTML.elem({tag:'div', attr:{class:'col-auto'}}, btn);
		btn = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Continue with Email Address');
		btn += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-danger','data-dismiss':'modal'}}, 'X');
		footer += fwHTML.elem({tag:'div', attr:{class:'col-auto'}}, btn);
		footer = fwHTML.elem({tag:'div', attr:{class:'row d-inline-flex justify-content-between w-100'}}, footer);
		
		let html = fwHTML.elem({tag:'input', attr:{id:'crtusr', type:'email', class:'form-control', placeholder:'Email'}});

		fwAPP.api.myMODAL(fwHTML.title('Enter your Email or Sign-in'), html, footer, function(){
			verifyUSR(email, function(data){
				$('#crtusr').val(data.email).focus();
			});
		}, function(event){
			let flds = getFLDS(['crtusr']);
			if (fwAPP.api.eventis(event, 'btn-text-bg-dark-gz')){
				fwAPP.api.loadACTION('signin');
				$('#signin_email').val(flds.crtusr).focus();
			} else if (fwAPP.api.eventis(event, 'btn-primary')){
				verifyUSR(flds.crtusr, function(data){
					if (!isEmpty(data.verified) && data.verified){
						fwAPP.auth.CRTUSR(data);
						fwAPP.mymodal.hide();
						item.add(accno, prdid, prdtyp);
						fwAPP.api.loadACTION('cart');
					}						
					$('#crtusr').val(data.email).focus();
				});
				return;
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.orgpay = function(pasid){
		let sysval = fwAPP.api.get('sysval');
		let evt = fwAPP.api.get('evtsle',{pasid:pasid});
		let pas = fwAPP.api.get('evtsle',{pasid:pasid, obj:'pas'});
fwLogger.error('orgpay', evt, pas);
		function capitalize(s) {
			if (isEmpty(s)) return '';
			return s.charAt(0).toUpperCase() + s.slice(1);
		}
		if (isEmpty(evt.paynote)) evt.paynote = 'Event organizer contact.';
		
		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Ok');
		let text = fwHTML.elem({tag:'div', attr:{'class':'pre-wrap'}}, capitalize(evt.paynote));
		
		let html = fwHTML.subtitle(evt.name) + fwHTML.elem({tag:'div', attr:{class:'fs-5 fw-medium fst-italic m-2'}}, text);
		html += fwHTML.elem({tag:'select',attr:{id:'interest',class:'form-select text-capitalize'}}, setSELECT(sysval.dtaint,2));
		
		html += fwHTML.elem({tag:'label', attr:{class:'fw-bold fst-italic'}}, 'email');
		html += fwHTML.elem({tag:'input', attr:{id:'email', type:'email', class:'form-control'}});
		html = fwHTML.elem({tag:'div', attr:{class:'card-text form-group text-start'}}, html);

		html += fwHTML.elem({tag:'label', attr:{id:'label_name', class:'fw-bold fst-italic'}}, 'name');
		html += fwHTML.elem({tag:'input', attr:{id:'note', type:'text', class:'form-control'}});
		html = fwHTML.elem({tag:'div', attr:{class:'form-group text-start'}}, html);

		fwAPP.api.myDLG({title:'Event Organizer Note', html:html, footer:footer, cbinit:function(){
			$('#interest option[value=1]').addClass('text-bg-success');

			$('#interest').off('change').on('change', function(e){
				$('#label_name').html( ( $('#interest').val()==1 ? 'name used for payment' : 'name'));
			});
		}, callback:function(){
			let opt = getFLDS();
			opt.evtid = evt.id;
			opt.pasid = pasid;
			fwAPP.api.srvREQ({fn:'set', cmd:'EVT_FUP', opt:opt, callback:function(data){
				fwHTML.msg('success', 'Event Information', "Thank you, for making it easier to organize gigzantic events.", 15);
			}, err_callback:function(result){
			}});
		}});
	};
	
	this.setupACH = function(){
		getDLGpage('payment_ach_setup');
	};

	this.resync = function(mode, callback){
		if (isEmpty(mode)) mode = 'update';
		let frame = fwAPP.api.get('frame');
		
		fwLogger.error('cart.resync', mode, frame);
		switch(mode){
			case 'payment':
			case 'status': mode = frame; break;
		}
		
		let req = {fn:'GIGPASSget', cmd:'CRT_GET'};
		let opt = {crtid:cartID(), mode:mode};
		if (priv.ischeckout()){
			req = {fn:'GIGPASSset', cmd:'CRT_LCK'};
			let payment = fwAPP.api.get('cache').payment;
			fwLogger.error('cache.payment', payment);
			if (!isEmpty(payment)){
				opt.payment = 'psp';
				if (payment.ach) opt.payment = 'ach';
			}
		}
		fwAPP.api.srvREQ({fn:req.fn, cmd:req.cmd, opt:opt, callback:function(data){
			cartDRAW(fwAPP.api.set('cart', data));
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			fwAPP.api.loadACTIONclean('cart');
		}});
	};
	
	this.status = function(){
		let usr = crtUSR();
fwLogger.error('cart.status', usr);
		
		if (usr.id>0 && fwAPP.api.modeUI()) gFn.resync('status');
	};
	
	this.redeem = function(){
		function applyPROMO(promo){
			let cart = fwAPP.api.get('cart');
			let check = checkPROMO();

			beep('ping');
			
			if (check.promo) return fwHTML.msg('info', 'Redeem', '['+ promo.name +'] is already applied.', 15);
			return fwAPP.cart.sleadd(promo.acccr, promo.id, 0);
		}
		
		let flds = getFLDS(['promo']);
	
		if (!isEmpty(flds.promo)){
			flds.promo = String(flds.promo).toUpperCase();
			
			$('#promo').val('');
			fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'CRT_PROMO', opt:{crtid:cartID(), promo:flds.promo}, callback:function(data){
				applyPROMO(data);
			}, err_callback:function(result){
				let msg = 'is not a valid promotion code.';
				if (result.text == 'INVALID_CRTITM') msg = 'unable to apply to a current cart item.'; 
				fwHTML.msg('warning', 'Redeem', '['+ flds.promo +'] '+ msg, 15);
				beep('error');
			}});
		}
	};
	
	this.order = function(){
		function cleanup(){
			if (!fwAPP.auth.SIGNEDIN()) fwAPP.auth.CRTUSR({});
			fwAPP.api.loadACTIONclean('sale');
		}
		
		let cache = fwAPP.api.get('cache');
		let opt = cache.payment;
		opt.paytype = (isEmpty(opt.ach) ? 'psp' : 'ach');
		opt.crtid = cartID();
//		opt.amt = parseFloat(String($('#total_amt').html()).match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)[0]);
		opt.amt = currency($('#total_amt').html(),{symbol:'',separator:''}).format();
		
		fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'CRT_ORD', opt:opt, callback:function(data){
			fwHTML.msg('success','Order','Sale is completed.',15);
			cleanup();
		}, err_callback:function(result){
			cleanup();
		}});
	};
	
	this.payment = function(){
		let cache = fwAPP.api.get('cache');
		let title = fwHTML.elem({tag:'h5',attr:{'class':'m-0 p-0 d-inline-flex pe-5'}},'Payment:');
		title += fwHTML.elem({tag:'div',attr:{'class':'form-check form-check-inline'}}, fwHTML.elem({tag:'label'},
			fwHTML.elem({tag:'input',attr:{'id':'credit','name':'payoption','type':'radio','checked':'checked','class':'form-check-input'}}) +'Credit Card'));
		title += fwHTML.elem({tag:'div',attr:{'class':'form-check form-check-inline text-primary'}}, fwHTML.elem({tag:'label'}, 
			fwHTML.elem({tag:'input',attr:{'id':'paypal','name':'payoption','type':'radio','class':'form-check-input'}}) + fwHTML.icon({type:'brands',name:'paypal'}) +'ayPal'));
		title += fwHTML.elem({tag:'div',attr:{'class':'form-check form-check-inline text-dark'}}, fwHTML.elem({tag:'label'}, 
			fwHTML.elem({tag:'input',attr:{'id':'ach','name':'payoption','type':'radio','class':'form-check-input'}}) + fwHTML.icon({type:'solid',name:'money-check-dollar',text:'success'}) +' ACH'));

		title = fwHTML.elem({tag:'form'}, title);
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Confirm');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');

		let html = fwHTML.elem({tag:'div',attr:{'id':'my_dlg_page'}});
		
		fwAPP.api.myMODAL(title, html, footer, function(){
			getDLGpage();
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				fwLogger.error('cache', cache);
				let accept = false;
				cache.payment = getFLDS();
				if ($('#paypal').prop('checked')){
					accept = true;
					cache.payment.paypal = true;
					delete cache.payment.ach;
					delete cache.payment.credit;
				} else {
					fwHTML.validate(event,function(){
						accept = true;
						if ($('#ach').prop('checked')){
							if (cache.dlgpage == 'payment_ach_setup'){
								accept = (cache.payment.acctno == cache.payment.acctno2);
								if (!accept){
									fwHTML.msg('danger','Account Number','Account and confirm number did not match.', 15);
									$('#acctno2').val('');
								}
							}
							cache.payment.ach = true;
							delete cache.payment.credit;
							delete cache.payment.paypal;
						} else {
							cache.payment.credit = true;
							delete cache.payment.ach;
							delete cache.payment.paypal;
						}
					});
				}
			
				fwAPP.api.set('cache', cache);
				gFn.resync('payment');
				
				if (accept){
					if (cache.dlgpage == 'payment_ach_setup'){
						let name = isEmpty(cache.payment.name) ? cache.dlgpage.acc_name : cache.payment.name;

						let opt = {type:cache.payment.type, rn:cache.payment.routingno, accno:cache.payment.acctno, name:name};
						fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ACH_LNK', opt:opt, callback:function(data){
							fwHTML.msg('success', 'ACH Setup', 'Processing your ACH account link setup request.', 15);
						}, err_callback:function(result){
							fwHTML.msg('info', 'ACH Setup', result.text, 15);
						}});
						return fwAPP.mymodal.hide();
					}
				} else return;
			}
			fwAPP.mymodal.hide();
		});
	};

	function msgANDclean(msg){
		if (!isEmpty(msg)) fwHTML.msg('info', 'Cart', msg, 10);
		if (!fwAPP.auth.SIGNEDIN()) fwAPP.auth.CRTUSR({});
		setTimeout(function(){
			if (priv.iscart()) fwAPP.api.loadACTIONclean();
		}, (isEmpty(msg) ? 100 : 3500));
	}
	
	let item = {
		add:function(accno, prdid, prdtyp, callback){
			accno = parseInt(accno);
			prdid = parseInt(prdid);
			let frame = fwAPP.api.get('frame');
			let opt = {crtid:cartID(), mode:frame, accno:accno, prdid:prdid, prdtyp:prdtyp};
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'CRT_ITM_ADD', opt:opt, callback:function(data){
				let cart = fwAPP.api.set('cart', data);
				if (typeof callback === 'function') return callback(cart);

				let result = priv.iscart_and_checkout() ? cartDRAW(cart) : fwAPP.api.loadACTIONclean('cart', cart);
				shake('#cart_action');
			}, err_callback:function(result){
				if (result.text == 'USRID_OWNER') msgANDclean('Passes for organizer owned events can be reserved under event management interface!');
			}});
		},
		update:function(id, qty){
			id = parseInt(id);
			qty = parseInt(qty);
			let cmd = (qty > 0) ? 'CRT_ITM_UPD' : 'CRT_ITM_RMV';
			let itm = (qty > 0) ? [{id:id, qty:qty}] : [id];
			item.crtgrpupd(cmd, itm);
		},
		crtgrpupd:function(cmd, itm){
			let frame = fwAPP.api.get('frame');
			let opt = {crtid:cartID(), mode:frame, itm:itm};
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:cmd, opt:opt, callback:function(data){
				let cart = fwAPP.api.set('cart', data);
				let result = isCART(cart) ? cartDRAW(cart) : msgANDclean(fwAPP.auth.SIGNEDIN() ? '' : 'No items to purchase are found in the cart!');
			}, err_callback:function(result){
			}});
		}
	};
}