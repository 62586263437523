function fwSEARCH(opt){
	const gFn = this;
	let prev_text = '';
	let cmd = 'init';
	
	this.change = function(){};
	this.onmode = function(){};
	
	let dOPT = {
		id:'page_root',
		mask:'filter|search',
		mode:'filter',
		clctnr:'row row-cols-1 row-cols-lg-2 text-start m-3 p-0',
		clelem:'col'
	};
	
	if (typeof opt === 'string'){
		dOPT.id = opt;
	} else {
		$.each(dOPT, function(k, v){
			fwLogger.log(k, v);
			if (!isEmpty(opt[k])) dOPT[k] = opt[k];
		});
	}
	
	function isMASK(s){
		return (dOPT.mask.indexOf(s)>=0);
	}

	function srchBTNinit(){
		let html = srchBTN();
		html = fwHTML.elem({tag:'button', attr:{id:'srch_srch', class:'btn btn-sm btn-secondary fst-italic d-flex align-items-center text-capitalize'}}, html);
		
		icon = fwHTML.icon({name:'shuffle', type:'solid'});
		if (isMASK('|')) html += fwHTML.elem({tag:'button', attr:{id:'srch_mode', class:'btn btn-primary fst-italic d-flex align-items-center'}}, icon);
		return html;
	}
	
	this.srchBTNmode = function(m){
		m = isEmpty(m) ? gFn.mode() : ((gFn.mode() == m && m == 'filter') ? 'search' : 'filter'); // switch to desired mode
//		if (!isEmpty(m)) gFn.mode((gFn.mode() == m && m == 'filter') ? 'search' : 'filter'); // switch to desired mode
		
		let srch = $('#srch_srch');
		let mode = $('#srch_mode');
		
//		srch.removeClass('btn-primary').removeClass('btn-secondary');
//		mode.removeClass('btn-primary').removeClass('btn-secondary');
		if (m == 'search'){
			gFn.mode('filter');
			srch.removeClass('btn-secondary').addClass('btn-primary');
			mode.removeClass('btn-primary').addClass('btn-secondary');
		} else {
			gFn.mode('search');
			srch.removeClass('btn-primary').addClass('btn-secondary');
			mode.removeClass('btn-secondary').addClass('btn-primary');
		}
		$('#srch_srch').html(srchBTN());
	};
	
	function srchBTN(action){
		let name = gFn.mode();
		action = isEmpty(action) ? name : action;
		let icon = fwHTML.icon({name:name, type:'solid'});
		$('#srch_input').attr('placeholder', 'Enter '+ name +' text ...');
		return icon + fwHTML.elem({tag:'div', attr:{class:'d-none d-md-block'}}, action);
	}
	
	function srchSRCH(c){
		cmd = c;
			
		let id = $(this).prop('id');
		let txt = $('#srch_input').val().trim();
		if (id == 'srch_clr') txt = '';
		gFn.set('text', txt);
		
		let length = String(txt).length;
		let trgr = ((length>0 || id == 'srch_clr' || prev_text == 'mode-switch') && txt != prev_text);
		
		if (trgr){
			gFn.query();
		} else {
			$('#srch_input').focus();
			$('#srch_srch').html(srchBTN(fwHTML.spinner()));
			setTimeout(function(){
				$('#srch_srch').html(srchBTN());
			}, 150);
		}
		prev_text = txt;
	}
	
	this.init = function(){
		let html = fwHTML.elem({tag:'input', attr:{id:'srch_input', type:'text', class:'form-control fst-italic', placeholder:'Enter text ...'}});
		
		html += srchBTNinit();

		icon = fwHTML.icon({name:'angles-left', type:'solid', pos:'center'});
		let clear = fwHTML.elem({tag:'button', attr:{id:'srch_clr', class:'btn btn-outline-danger srch-trgr'}}, icon);
		
		html = fwHTML.elem({tag:'div', attr:{class:'input-group input-group-sm pt-1 px-3'}}, clear + html);
		html = fwHTML.elem({tag:'div', attr:{class:'col p-0 bg-white'}}, html);
		html = fwHTML.elem({tag:'div', attr:{class:'row sticky-top'}}, html);
		
		html += fwHTML.elem({tag:'div', attr:{id:'srch_content', class:dOPT.clctnr}});
		
		$('#'+ dOPT.id).html(html);

		$('#srch_clr').off('click').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			gFn.set('clr');
			srchSRCH('clr');
		});
		$('#srch_mode').off('click').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			gFn.srchBTNmode();
			srchSRCH('mode');
		});
		$('#srch_srch').off('click').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			srchSRCH('click');
		});
		
		gFn.set('clr');
		srchBTNmode(gFn.mode());
	};
	
	this.wrap = function(k, v, callback){
		let myclass = dOPT.clelem + (i % 2) ? ' bg-light' : ''; // alternate color each row
		return fwHTML.elem({tag:'div', attr:{class:myclass}}, callback(k, v));
	};
	
	this.write = function(lst, callback){
		let html = '';
		if (!isEmpty(lst)){
			$.each(lst, function(k, v){
				if (typeof callback === 'function') html += callback(k, v);
			});
		}
		return gFn.content(html);
	};
	
	let last = dOPT.mode;
	
	this.mode = function(mode){
		function set(m){
			return (m == 'filter') ? 'filter' : 'search';
		}
		
		if (!isEmpty(mode)) dOPT.mode = set(mode);

		if (last != dOPT.mode){
			cmd = prev_text = 'mode-switch';
			if (typeof gFn.onmode === 'function') gFn.onmode(dOPT.mode);
		}
		return dOPT.mode;
	};
	
	this.set = function(type, text){
		switch(type){
			case 'addrow':
				let myclass = dOPT.clelem + ($('#srch_content > div.row').length % 2) ? ' bg-light' : ''; // alternate color each row
				gFn.append(fwHTML.elem({tag:'div', attr:{class:myclass}}, text));
				break;
				
			case 'clrrow': 
				gFn.html('');
				break;
			
			case 'clrall':
				gFn.content('');
				/* falls through */
			case 'clr':	
				text = '';
				prev_text = 'mode-switch';
				/* falls through */
			case 'text': 
				$('#srch_input').val(text);
				let result = isEmpty(text)? $('#srch_clr').hide() : $('#srch_clr').show();
				break;
				
			case 'label': 
				$('#srch_input').attr('placeholder', text);
				break;
		}
	};
	
	this.append = function(html){
		$('#srch_content').append(html);
	};
	
	this.content = function(html, attr){
		$('#srch_content').html(html).attr('class', isEmpty(attr) ? dOPT.clctnr : attr);
	};
	
	this.get = function(){
		return $('#srch_input').val().trim();
	};
	
	this.query = function(){
		if (isEmpty(cmd) || cmd == 'query') return fwLogger.error(query, cmd);
		cmd = 'query';
		
		$('#srch_srch').html(srchBTN(fwHTML.spinner()));
		let txt = gFn.get();
		$('#srch_input').focus();
		if (typeof gFn.change === 'function') gFn.change(txt, prev_text, dOPT.mode, dOPT.id);
		
		return this;
	};
	
	this.endquery = function(){
		cmd = '';
		$('#srch_srch').html(srchBTN());
	};
	
	gFn.init();
	return gFn;
}